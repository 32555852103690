body {
  @apply min-h-full text-sm font-normal overscroll-none overflow-hidden antialiased text-light-base-600 dark:text-dark-base-600;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  @apply m-0;
}

ol,
ul {
  @apply list-none m-0 p-0;
}

:focus {
  @apply outline-none;
}

app-layout {
  @apply grid grid-cols-min-max-1fr grid-rows-min-1fr grid-areas-sidenav-header-main  h-screen;
}

.loader {
  @apply flex items-center justify-center h-14;
}

.sidenav {
  @apply relative p-0 m-0 grid-area-sidenav;
}

.header {
  @apply relative p-0 m-0 grid-area-header;
}

.main {
  @apply relative p-0 m-0 grid-area-main h-full;
}

a {
  @apply no-underline cursor-pointer text-light-brand-100 hover:text-light-brand-200 dark:text-dark-brand-100 dark:hover:text-dark-brand-200;
}

mat-icon {
  @apply text-light-base-500 dark:text-dark-base-500;
}

button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  text-rendering: optimizeLegibility;
}
