.date-range {
  & .mat-mdc-selection-list {
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    & .mat-mdc-list-item.mat-list-option {
      height: 38px;
      font-size: 14px !important;
      font-weight: 500;
    }
  }

  & .mat-mdc-form-field {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    & .mat-form-field-wrapper {
      padding-bottom: 0;
      margin: 0 !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    & .mat-form-field-infix {
      width: 80px !important;
      padding: 0 !important;
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      & .mat-form-field-label-wrapper {
        padding: 0 !important;
      }
    }
  }
  & mat-calendar {
    @apply border border-solid border-t border-l-0 border-r-0 border-b-0 border-light-base-300 dark:border-dark-base-300;
    & .mat-calendar-controls {
      margin-top: 0 !important;
    }
  }
}
