.new-stream-pill {
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: $LT-brand-primary;
  padding: 8px 12px;
  border-radius: 25px;
  font-weight: 500;
  font-size: 13px;
  z-index: 500;
  cursor: pointer;
}

.channel-selector-menu {
  & button {
    display: flex;
    align-items: center;
    min-width: 200px;

    & img {
      border-radius: 50%;
      margin-right: $margin-sm;
    }
  }
}

// syndication-activity.component.scss
app-syndication-activity {
  & .article-content {
    & figure {
      margin: 0;

      & img {
        width: 100%;
        object-fit: cover;
      }
    }

    blockquote {
      font-size: 18px;
    }
  }
}

// carousel-attachment.component.scss
app-carousel-attachment .tile {
  box-shadow: none !important;
}

// stream-header.component.scss
app-stream-header {
  & .cdk-drag-handle.drag-handle {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: grab;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
app-stream .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
app-stream .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  background-color: $LT-brand-primary;
  color: $white;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.dark app-stream .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  background-color: $DK-brand-primary;
}

app-stream .mat-mdc-form-field {
  display: block;
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  & .mat-form-field-wrapper {
    padding-bottom: 0;
    margin: 0;
  }
}

// feed.component.scss
app-stream.cdk-drag-preview {
  opacity: 0.8;
}

// facebook-comment.component.scss
.second-level {
  & app-user-avatar .user-thumbnail-small {
    height: 24px;
    width: 24px;
  }
}

//
// Dark theme
//
.dark {
  app-streams {
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    & .mat-tab-label {
      border-right: 1px solid $DK-border;
      color: $DK-secondary-text;

      &:last-child {
        border-right: 0;
      }
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      &.mat-tab-label-active {
        background: $DK-background;
        color: $DK-brand-primary;

        &:after {
          background: $DK-background;
        }

        & .close-tab:hover {
          background: $DK-sidenav;
        }
      }

      &.cdk-program-focused {
        background: transparent;
        /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        &.mat-tab-label-active {
          background: $DK-background !important;
        }
      }
    }

    .button-tab,
    .close-tab {
      &:hover {
        background-color: $DK-background;

        mat-icon {
          color: $DK-primary-text;
        }
      }

      mat-icon {
        color: $DK-secondary-text;
      }
    }

    .tab-edit {
      background: $DK-sidenav;
      border-color: $DK-brand-primary;
      color: $DK-primary-text;
    }

    .mat-ink-bar::after {
      content: "";
      width: 1px;
      height: 2px;
      position: absolute;
      left: -1px;
      background: $DK-brand-primary;
    }
  }

  .new-stream-pill {
    background-color: $DK-brand-primary;
  }
}

.linkedin-repost,
.quoted-tweet {
  @apply border border-solid border-light-base-300/90 dark:border-dark-base-300/90 rounded-md mx-3 overflow-hidden;
  .link-metadata {
    @apply rounded-b-md;
  }

  app-video-attachment,
  app-image-attachment {
    @apply rounded-b-md overflow-hidden isolate;
  }

  .poll-wrapper {
    @apply mb-4;
  }

  app-carousel-attachment .doc-title {
    @apply border-b-0;
  }
}

.linkedin-repost-header {
  & .channel-name {
    @apply line-clamp-1;
  }

  .activity-header.empty {
    .activity-text,
    app-icon {
      @apply hidden;
    }

    app-profile-picture {
      @apply h-6 w-6 #{!important};
      img {
        @apply h-6 w-6 #{!important};
      }
    }
  }
}

app-preview-dialog app-linkedin-activity.linkedin-repost,
.publisher-scheduled app-linkedin-activity.linkedin-repost,
.publisher-published app-linkedin-activity.linkedin-repost {
  @apply pt-3 mb-3;
  & .activity-text {
    @apply pt-3;
  }
}

.send-to-employees-button {
  @apply h-9 min-h-[32px];
}
