.composer-actions {
  & .mdc-tab-indicator {
    @apply -bottom-px top-[unset];
    & .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
      @apply border-light-base-0 dark:border-dark-base-25 #{!important};
    }
  }

  & .mat-mdc-tab-body,
  & .mat-mdc-tab-body.mat-mdc-tab-body-active,
  & .mat-mdc-tab-body-content,
  & .mat-mdc-tab-header,
  & .mat-mdc-tab-label-container {
    @apply overflow-visible;
  }

  & .mat-mdc-tab-label-container {
    @apply z-50;
  }

  & .mat-mdc-tab-body-wrapper {
    @apply bg-light-base-0 dark:bg-dark-base-25 p-4 border border-solid border-light-base-300/90 dark:border-dark-base-300/90 rounded-b-[4px] rounded-r-[4px];
  }

  & app-tags-input {
    @apply block;
    & .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
      @apply hidden;
    }
  }

  & .mat-mdc-tab-labels .mdc-tab {
    @apply h-10 px-4 border-solid border border-transparent dark:border-transparent;
    &.mdc-tab--active,
    &:hover.mdc-tab--active {
      @apply bg-light-base-0 dark:bg-dark-base-25 rounded-t-[4px] px-4 border border-b-0 border-solid border-light-base-300/90 dark:border-dark-base-300/90;
      & .mdc-tab__text-label {
        @apply text-dark-base-100 dark:text-light-base-100;
      }
    }
  }

  & .mat-mdc-tab:hover .mdc-tab__ripple::before {
    @apply opacity-0;
  }

  & app-timezone-selector {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    & .mat-form-field-wrapper {
      @apply pb-0 mb-4;
    }
  }

  .schedule-date-time {
    @apply grid grid-cols-2 gap-4;

    & .mat-slide-toggle-label {
      @apply inline-flex flex-nowrap;
    }

    & .schedule-date .mat-mdc-form-field {
      @apply inline;
    }

    & .schedule-date {
      & button {
        @apply flex items-center justify-center;
      }

      & .mat-mdc-icon-button svg,
      .mat-mdc-icon-button img {
        @apply h-5 w-5;
      }

      .mat-mdc-form-field-subscript-wrapper {
        @apply hidden;
      }
    }

    & .schedule-time .time {
      @apply flex;

      & app-time-picker {
        @apply flex w-full;
      }

      & .mat-mdc-form-field {
        @apply w-full;
      }
    }
  }

  & .round .mat-mdc-tab-body-wrapper {
    @apply rounded-tl-[4px];
  }

  &:hover .mdc-tab__ripple::before,
  &:active .mdc-tab__ripple::before,
  & .mdc-tab__ripple::before {
    @apply opacity-0 #{!important};
  }
}
