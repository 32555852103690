[hidden] {
  @apply hidden #{!important};
}

app-icon-button .mat-mdc-button > mat-icon.mat-icon {
  @apply mr-0;
}

.mat-mdc-menu-panel,
.mat-mdc-dialog-container,
.mat-mdc-dialog-container .mdc-dialog__surface,
.mat-expansion-panel,
.mat-mdc-autocomplete-panel,
.mat-mdc-select-panel,
.mat-datepicker-content,
.mat-button-toggle-appearance-standard {
  @apply bg-light-base-0 dark:bg-dark-base-100;
}

input.mat-mdc-form-field-input-control.mdc-text-field__input {
  @apply h-6 leading-6 text-sm font-normal;
}

.mat-mdc-form-field-flex label.mdc-floating-label.mat-mdc-floating-label {
  @apply text-sm font-normal h-6 leading-6 -mt-px;
  & mat-label {
    @apply inline-block text-sm font-normal h-6 leading-6;
  }
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
  @apply pr-2;
}

// Checkbox
mat-checkbox .mdc-checkbox {
  @apply h-4 w-4 basis-4 p-2.5;
  & .mdc-checkbox__checkmark {
    @apply h-3 w-3 inset-px;
  }

  & .mdc-checkbox__mixedmark {
    @apply w-2.5;
  }
}

mat-checkbox .mdc-form-field .mdc-checkbox__background {
  @apply h-4 w-4 top-2.5 left-2.5 border;
}

mat-checkbox.mdc-checkbox.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background {
  @apply border-light-base-400/80 dark:border-dark-base-400;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  @apply text-light-base-0 dark:text-light-base-0 #{!important};
}

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  @apply border-light-base-0 dark:border-light-base-0 #{!important};
}

.mat-mdc-menu-panel.selector-menu .mat-mdc-menu-content {
  & button.mdc-button {
    @apply rounded-[0px] justify-start px-4;
  }
}

// toggles
mat-slide-toggle .mdc-form-field {
  & .mdc-switch .mdc-switch__handle {
    @apply w-[18px] h-[18px];
    & .mdc-switch__icon {
      @apply w-3 h-3;
    }
  }

  .mdc-switch .mdc-switch__handle-track {
    @apply w-[calc(100%-18px)];
  }

  & .mdc-switch {
    &.mdc-switch--checked .mdc-switch__handle::before {
      @apply bg-light-brand-100 dark:bg-dark-brand-100 border border-solid border-light-brand-100/10 dark:border-dark-brand-100/10;
    }

    &.mdc-switch--checked .mdc-switch__track::after,
    .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
      @apply bg-light-brand-100/30 dark:bg-dark-brand-100/30 border border-solid border-light-brand-100/10 dark:border-dark-brand-100/10 #{!important};
    }

    &.mdc-switch--selected .mdc-switch__icon {
      @apply fill-light-base-0 dark:fill-dark-base-0;
    }

    &.mdc-switch--selected.mdc-switch--checked.mdc-switch--disabled .mdc-switch__track::after {
      @apply bg-light-brand-100/50 dark:bg-dark-brand-100/30 #{!important};
    }
  }

  & .mdc-label {
    @apply pl-2;
  }
}

// list option
mat-list-option {
  &.mat-mdc-list-item:focus,
  &.mdc-list-item:focus::before {
    @apply bg-light-base-50 dark:bg-dark-base-0;
  }
}

// Chips
mat-chip-grid {
  & .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    @apply bg-light-base-100 dark:bg-dark-base-25 border border-solid border-light-base-200 dark:border-dark-base-300;
  }
}

.mat-mdc-chip.mat-mdc-standard-chip {
  @apply h-7;
}

.mdc-evolution-chip-set .mdc-evolution-chip {
  @apply my-0.5 ml-1 #{!important};
}

.mdc-evolution-chip-set__chips {
  @apply items-center;
}

// Tabs
.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab.mdc-tab--active:focus .mdc-tab__text-label {
  @apply text-light-brand-100 dark:text-dark-brand-100;
}

// input

mat-form-field .mat-mdc-form-field-infix {
  @apply flex items-center;
}

mat-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading,
mat-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch,
mat-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
  @apply border-light-base-400/30 dark:border-dark-base-400/20;
}

mat-form-field
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-notched-outline.mdc-notched-outline__leading,
mat-form-field
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-notched-outline.mdc-notched-outline__notch,
mat-form-field.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover.mdc-notched-outline.mdc-notched-outline__trailing {
  @apply border-light-base-400 dark:border-dark-base-400;
}

mat-form-field.mat-focused .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading,
mat-form-field.mat-focused .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch,
mat-form-field.mat-focused .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
  @apply border-light-brand-100 dark:border-dark-brand-100;
}

mat-form-field.mat-focused .mdc-floating-label.mat-mdc-floating-label {
  @apply text-light-brand-100 dark:text-dark-brand-100;
}

mat-form-field.mdc-text-field--invalid .mdc-floating-label.mat-mdc-floating-label {
  @apply text-light-brand-100 dark:text-dark-brand-100;
}

.mdc-button.mdc-button--unelevated.mat-mdc-unelevated-button.mat-primary.mat-mdc-button-base {
  @apply bg-light-brand-100 dark:bg-dark-brand-100;
  &.mat-mdc-unelevated-button[disabled][disabled] {
    @apply bg-light-base-300 dark:bg-dark-base-300;
  }
}

mat-form-field.ng-invalid.mat-form-field-invalid.ng-touched.mat-mdc-form-field-flex.mdc-notched-outline.mdc-notched-outline__leading,
mat-form-field.ng-invalid.mat-form-field-invalid.ng-touched.mat-mdc-form-field-flex.mdc-notched-outline.mdc-notched-outline__notch,
mat-form-field.ng-invalid.mat-form-field-invalid.ng-touched.mat-mdc-form-field-flex.mdc-notched-outline.mdc-notched-outline__trailing {
  @apply border-light-danger-100;
}

:is(
    .dark
      mat-form-field.ng-invalid.mat-form-field-invalid.ng-touched
      .mat-mdc-form-field-flex
      .mdc-notched-outline
      .mdc-notched-outline__leading
  ),
:is(
    .dark
      mat-form-field.ng-invalid.mat-form-field-invalid.ng-touched
      .mat-mdc-form-field-flex
      .mdc-notched-outline
      .mdc-notched-outline__notch
  ),
:is(
    .dark
      mat-form-field.ng-invalid.mat-form-field-invalid.ng-touched
      .mat-mdc-form-field-flex
      .mdc-notched-outline
      .mdc-notched-outline__trailing
  ) {
  @apply border-dark-danger;
}

// tooltip
.mat-mdc-tooltip .mdc-tooltip__surface {
  @apply dark:bg-dark-base-300;
}

// Datepicker & Date range
.app-datepicker {
  & .mat-calendar-body-selected {
    @apply text-light-base-0 dark:text-dark-base-0 font-medium;
  }

  & .mat-mdc-icon-button[disabled][disabled] {
    @apply text-light-base-400/50 dark:text-dark-base-400/50;
  }
}

.mat-datepicker-content,
.date-range-menu {
  @apply mt-2 bg-light-base-0 dark:bg-dark-base-25 border border-b-0 border-solid border-transparent dark:border-dark-base-300/90;
}

.date-range-menu,
mat-datepicker-content {
  & .mat-calendar-body-selected {
    @apply text-light-base-0 dark:text-dark-base-0 font-medium;
  }

  & app-date-range mat-selection-list mat-list-option .mdc-list-item__primary-text {
    @apply text-light-base-600 dark:text-dark-base-600;
  }

  .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
    @apply text-light-base-600 dark:text-dark-base-600;
  }
}

// icons

mat-form-field {
  .mat-mdc-icon-button svg,
  .mat-mdc-icon-button img {
    @apply w-[inherit] h-[inherit] #{!important};
  }
}

.cdk-overlay-pane:has(.analytics-channel-selector-menu-wrapper.mat-mdc-select-panel) {
  min-width: fit-content;
}
.cdk-overlay-pane:has(.analytics-widget-employee-group-selector.mat-mdc-select-panel) {
  min-width: fit-content;
}

// progress-bar
.mat-mdc-progress-bar {
  @apply rounded-sm;
}
