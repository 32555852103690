.app-scrollbar.ng-scrollbar {
  --scrollbar-border-radius: 7px;
  --scrollbar-padding: 2px;
  --scrollbar-viewport-margin: 0;
  --scrollbar-thumb-color: rgba(0, 0, 0, 0.3);
  --scrollbar-thumb-hover-color: rgba(0, 0, 0, 0.5);
  --scrollbar-size: 7px;
  .dark & {
    --scrollbar-thumb-color: rgba(255, 255, 255, 0.3);
    --scrollbar-thumb-hover-color: rgba(255, 255, 255, 0.5);
  }
  .ng-scroll-content {
    display: block;
  }
  & .scrollbar-control {
    z-index: 99999;
  }
}
app-stream {
  .app-scrollbar scrollbar-y.scrollbar-control {
    right: 5px !important;
    top: 2px !important;
  }
}
