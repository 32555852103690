.sso-configuration {
  .mat-step {
    @apply bg-light-base-0 dark:bg-dark-base-0;
  }

  .mat-vertical-stepper-header {
    @apply p-5;
  }

  .mat-vertical-content-container {
    @apply ml-[30px];
  }

  .mat-step-label {
    & .mat-step-text-label {
      @apply text-light-base-400 dark:text-dark-base-500;
    }
    &.mat-step-label-active.mat-step-label-selected .mat-step-text-label {
      @apply text-light-base-600 dark:text-dark-base-600 font-medium;
    }
  }

  .mat-step-icon {
    @apply bg-light-base-400 dark:bg-dark-base-300 h-5 w-5;

    &.mat-step-icon-state-number {
      @apply text-light-base-0 dark:text-light-base-0;
    }

    &.mat-step-icon-selected {
      @apply font-medium bg-light-brand-100 dark:bg-dark-brand-100;
    }
  }

  .mat-step-icon-content {
    @apply text-xs;
  }

  .sso-domain-list-table {
    @apply min-w-[900px];

    mat-cell,
    mat-header-cell {
      @apply self-stretch box-content font-medium text-xs;
    }

    .mat-mdc-cell,
    .mat-header-cell {
      @apply border-b-light-base-200 dark:border-b-dark-base-400/10;
    }

    .mat-column-domain {
      @apply max-w-[200px];
    }

    .mat-column-record {
      @apply max-w-[60px];
    }

    .mat-column-status {
      @apply max-w-[60px];
    }

    .mat-column-value {
      @apply w-full px-2;
    }

    .mat-column-options {
      @apply justify-end max-w-[100px] pr-3 #{!important};
    }

    mat-row.mat-mdc-row {
      @apply bg-white dark:bg-dark-base-0 hover:bg-[#fafafa] dark:hover:bg-dark-base-25;
    }

    .mat-mdc-header-row,
    mat-header-row {
      @apply min-h-[48px] bg-light-base-50 dark:bg-dark-base-25 #{!important};
    }

    .mat-mdc-header-cell {
      @apply border border-t-0 border-b border-l-0 border-r-0 border-solid border-light-base-200 dark:border-dark-base-300;
    }

    .mat-mdc-no-data-row {
      @apply flex items-center justify-center min-h-[48px] h-12 text-light-base-400 dark:text-dark-base-400 bg-light-base-0 dark:bg-dark-base-0 #{!important};
    }

    .mat-mdc-row:last-child {
      @apply border-b-0;
    }

    mat-header-row,
    mat-header-row.mat-table-sticky-border-elem-top {
      @apply border border-t border-b-0 border-l-0 border-r-0 border-solid border-light-base-200 dark:border-dark-base-300 #{!important};
    }
  }
}

.sso-domain-table-column-menu {
  .mat-mdc-menu-content {
    @apply min-w-[220px];
  }

  .mat-mdc-menu-item {
    @apply leading-[40px] h-10 px-2 min-h-[40px];
  }
}

.domain-list-table-loading {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: indeterminate-progress-bar;
}

@keyframes indeterminate-progress-bar {
  from {
    left: -50%;
  }
  to {
    left: 150%;
  }
}
