// TODO Refactor for Light/Dark theme handling

app-publisher {
  display: grid;
  grid-template-columns: min-content minmax(0, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: "menu content" "menu content";
  height: calc(100vh - #{$page-header-height});
}

.publisher-menu {
  grid-area: menu;
  position: relative;
  overflow: auto;
}

.publisher-content {
  grid-area: content;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: auto;

  & .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .column {
      box-sizing: border-box;

      &.content {
        width: 80%;
        padding: $padding-xl;

        & .masonry-grid {
          margin: 0 auto;
        }
      }

      &.filters {
        width: 20%;
        padding: $padding-xl $padding-xl 0 0;
        margin-left: auto;

        & .section {
          position: sticky;
          top: 20px;
        }
      }
    }

    .masonry-item {
      width: 300px;
      margin-bottom: 10px;
    }

    .empty-state {
      text-align: center;
      padding: 40px;
      width: 100%;

      mat-icon {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.publisher-activity {
  & .single-channel {
    @apply font-medium text-light-base-400 dark:text-dark-base-400 ml-1;
    & .single-channel-name {
      @apply text-light-base-600 dark:text-dark-base-400;
    }

    & .single-channel-handle {
      @apply text-xs mt-1;
    }
  }

  .channel-counter {
    @apply font-medium text-light-base-400 dark:text-dark-base-400 ml-2;
  }

  .publisher-library & {
    & .single-channel-handle {
      @apply mt-0;
    }
  }
}

.publisher-calendar {
  & app-profile-picture {
    margin-right: unset;
    margin-left: -10px;

    &:first-child {
      margin-left: 0px;
    }
  }

  app-link-preview .link-metadata {
    padding: $padding-sm !important;

    & .link-title {
      font-size: 10px !important;
      line-height: 1.2 !important;
      font-weight: 500 !important;
      margin-bottom: $margin-xxs !important;
    }

    & .link-domain {
      font-size: 9px !important;
      line-height: 1.2 !important;
      margin-top: 2px;
    }
  }

  app-link-preview .link-image {
    & .play-image {
      background-size: 42px 42px !important;
    }
  }

  app-activity-header {
    padding: $padding-sm;
    font-size: 10px;
  }

  & .publisher-activity {
    padding: 3px 3px 4px 3px;

    &.published,
    &.shared {
      filter: grayscale(100%);
      opacity: 0.65;
      transition: all ease-in-out 0.15s;

      &:hover {
        filter: none;
        opacity: 1;
      }
    }

    & app-activity {
      margin-bottom: 0 !important;
    }

    & .activity-text {
      font-size: 11px;
      font-weight: 400;
      line-height: 1.4;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    & .single-channel {
      @apply text-xs font-medium text-light-base-400 dark:text-dark-base-400 ml-0;
      & .single-channel-name {
        @apply text-light-base-600 dark:text-dark-base-400;
      }

      & .single-channel-handle {
        @apply text-[8px] mt-0 leading-3;
      }
    }

    & .channel-counter {
      @apply text-xs font-medium text-light-base-400 dark:text-dark-base-400 ml-0;
    }
  }
}

.publisher-library {
  & .publisher-activity app-activity {
    margin-bottom: 0 !important;
  }

  & .link-metadata {
    padding: 8px !important;
  }

  & .link-title {
    font-size: 12px !important;
    margin-bottom: 0 !important;
  }
}

app-publisher-activity {
  & .publisher-activity {
    & .activity-header {
      padding: 0 12px 12px 12px !important;

      & .activity-text {
        margin-top: 0 !important;
      }

      &.empty {
        display: none;
      }
    }
  }

  &.publisher-calendar .publisher-activity {
    & .sharing-note {
      @apply min-h-[32px] h-[32px] text-xs rounded-b-[4px] #{!important};
      & app-icon {
        @apply w-3.5 h-3.5 mr-px #{!important};
      }
    }
  }

  &.hide-tags,
  &.no-tags {
    & .link,
    & .first-photo.no-ratio img,
    & .second-photo img {
      border-bottom-right-radius: $border-radius-sm;
      border-bottom-left-radius: $border-radius-sm;
    }

    & .player.no-ratio {
      overflow: hidden;
      border-bottom-right-radius: $border-radius-sm;
      border-bottom-left-radius: $border-radius-sm;
    }

    & .single .first .photo {
      border-bottom-right-radius: $border-radius-sm;
      border-bottom-left-radius: $border-radius-sm;
    }

    & .double {
      & .first .photo {
        border-bottom-left-radius: $border-radius-sm;
      }

      & .second .photo {
        border-bottom-right-radius: $border-radius-sm;
      }
    }

    & .triple {
      & .second .photo {
        border-bottom-left-radius: $border-radius-sm;
      }

      & .third .photo {
        border-bottom-right-radius: $border-radius-sm;
      }
    }

    & .quad {
      & .third .photo {
        border-bottom-left-radius: $border-radius-sm;
      }

      & .fourth .photo {
        border-bottom-right-radius: $border-radius-sm;
      }
    }

    & .quint {
      & .third .photo {
        border-bottom-left-radius: $border-radius-sm;
      }

      & .fifth .photo,
      .fifth .more {
        border-bottom-right-radius: $border-radius-sm;
      }
    }

    & app-video-attachment video,
    & app-carousel-attachment .doc-title {
      @apply rounded-b-[4px] #{!important};
    }
  }

  &.no-tags app-publisher-activity-footer {
    display: none;
  }

  &.share-note,
  &.share-note app-video-attachment video,
  &.share-note app-carousel-attachment .doc-title {
    @apply rounded-b-none #{!important};
  }
}

app-publisher-activity {
  &.publisher-calendar {
    & .publisher-activity {
      & .activity-header {
        padding: 0 !important;

        & .activity-text {
          margin-top: 0 !important;
          font-size: 12px;
        }
      }
    }

    & app-publisher-activity-header {
      padding: $padding-sm;
      padding-bottom: 0;
    }

    & .source {
      margin-top: $margin-sm;

      &:hover {
        & app-profile-picture {
          margin-left: -10px;

          &:first-child {
            margin-left: 0px;
          }
        }
      }
    }

    &.hide-tags,
    &.no-tags app-video-attachment video,
    &.no-tags app-carousel-attachment .doc-title {
      @apply rounded-b-[4px] #{!important};
    }

    & app-image-attachment .more {
      font-size: 16px !important;
      font-weight: 500;
    }

    & .poll-wrapper {
      margin: 0 8px !important;
      padding: 8px !important;
    }

    &.no-tags {
      & .poll-wrapper {
        margin-bottom: 8px !important;
      }
    }

    & .poll-title {
      font-size: 12px !important;
    }

    & .carousel-navigation {
      &.previous,
      &.next {
        width: 12px;
        height: 12px;
      }
    }

    & .download-link,
    & .doc-title {
      font-size: 10px !important;
      height: 32px !important;
    }

    & .doc-title {
      padding: 0 8px !important;
    }

    &.share-note,
    &.share-note app-video-attachment video,
    &.share-note app-carousel-attachment .doc-title {
      @apply rounded-b-none #{!important};
    }
  }
}

.publisher-scheduled {
  .scheduled-date-time {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: $margin-md;
    color: $LT-secondary-text;
  }

  .publisher-scheduled {
    width: 400px;
  }

  .scheduled-activities {
    max-width: 400px;
    margin: 0 auto;
  }

  .scheduled-loader {
    display: block;
    margin: 0 auto;
    height: 90px;
  }

  .empty-timeslot {
    font-size: 12px;
    margin-bottom: $margin-xl;
    text-align: center;
    color: $LT-tertiary-text;
  }

  .dark & {
    .empty-timeslot {
      color: $DK-tertiary-text;
    }

    .string {
      &::before {
        background: $DK-border;
      }
    }

    .scheduled-date-time {
      color: $DK-secondary-text;
    }
  }
}

.calendar {
  & app-activity.error {
    border: 1px solid;
    border-color: $LT-warn-dark;
    border-radius: $border-radius-sm + 1;
  }
}

.dark .calendar {
  & app-activity.error {
    border-color: $DK-warn-primary;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.filters .mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin-top: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.dark .filters .mat-form-field-appearance-outline .mat-form-field-outline {
  color: $DK-border;
}

app-preview-dialog {
  & .no-tags {
    & .poll-wrapper {
      margin-bottom: 12px !important;
    }
  }
}

.publisher-calendar .publisher-activity .linkedin-repost {
  @apply mx-2 mb-2;
  & .activity-header {
    & app-icon {
      @apply hidden;
    }
    & app-profile-picture img {
      @apply h-6 w-6 #{!important};
    }
    & .activity-date {
      @apply hidden;
    }
    & .channel {
      @apply overflow-hidden;
    }
    & .channel-info {
      @apply overflow-hidden overflow-ellipsis whitespace-nowrap;
    }
    & .channel-name {
      @apply ml-1.5 block text-xs overflow-hidden overflow-ellipsis whitespace-nowrap;
    }
    & .activity-text {
      @apply line-clamp-2 pt-1.5;
    }
  }
}
