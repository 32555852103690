.channel-list {
  mat-cell,
  mat-header-cell {
    @apply self-stretch box-content font-medium text-xs;
  }

  .mat-mdc-cell,
  .mat-header-cell {
    @apply border-b-light-base-200 dark:border-b-dark-base-400/10;
  }

  .channel-table {
    @apply min-w-[800px];
  }

  .mat-column-select {
    @apply max-w-[40px] pr-0 pl-3 #{!important};
  }

  .mat-column-channel {
    @apply min-w-[200px] max-w-[320px];
  }

  .mat-column-handle {
    @apply min-w-[180px] max-w-[320px] px-2;
  }

  .mat-column-network {
    @apply max-w-[60px];
  }

  .mat-column-type {
    @apply max-w-[60px];
  }

  .mat-column-primary {
    @apply max-w-[60px];
  }

  .mat-column-options {
    @apply justify-end pr-3 #{!important};
  }

  .mat-mdc-paginator {
    @apply text-light-base-500 dark:text-dark-base-500 bg-light-base-0 dark:bg-dark-base-0 border-solid border-0 border-t border-t-light-base-200 dark:border-t-dark-base-300;

    & .mdc-text-field--outlined {
      --mdc-outlined-text-field-outline-width: 0px !important;
      --mdc-outlined-text-field-focus-outline-width: 0px !important;
    }

    & .mat-mdc-paginator-container {
      @apply px-4 justify-between;
    }
  }

  .mat-mdc-paginator-page-size-label {
    @apply hidden sm:block #{!important};
  }

  mat-row.mat-mdc-row.requires-attention,
  mat-row.mat-mdc-row.requires-attention:hover {
    @apply bg-[#fef9f2] dark:bg-dark-base-50;
    & .mat-column-attentionChannelName {
      @apply min-w-[200px] max-w-[200px];
    }

    & .mat-column-attentionChannelOptions {
      @apply max-w-[36px] justify-end px-3 #{!important};
    }

    & .mat-column-attentionChannelReason {
      @apply pl-2 pr-3;
    }
  }

  mat-row.mat-mdc-row {
    @apply bg-white dark:bg-dark-base-0 hover:bg-[#fafafa] dark:hover:bg-dark-base-25;
  }

  .mat-mdc-header-row,
  mat-header-row {
    @apply min-h-[48px] bg-light-base-50 dark:bg-dark-base-25 #{!important};
  }

  .mat-mdc-header-cell {
    @apply border border-t-0 border-b border-l-0 border-r-0 border-solid border-light-base-200 dark:border-dark-base-300;
  }

  .mat-mdc-no-data-row {
    @apply flex items-center justify-center min-h-[48px] h-12 text-light-base-400 dark:text-dark-base-400 bg-light-base-0 dark:bg-dark-base-0 #{!important};
  }

  .mat-mdc-row:last-child {
    @apply border-b-0;
  }

  mat-header-row,
  mat-header-row.mat-table-sticky-border-elem-top {
    @apply border border-t border-b-0 border-l-0 border-r-0 border-solid border-light-base-200 dark:border-dark-base-300 #{!important};
  }
}

.channel-table-column-menu {
  .mat-mdc-menu-content {
    @apply min-w-[220px];
  }

  .mat-mdc-menu-item {
    @apply leading-[40px] h-10 px-2 min-h-[40px];
  }
}

.channel-table-loading {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: indeterminate-progress-bar;
}

@keyframes indeterminate-progress-bar {
  from {
    left: -50%;
  }
  to {
    left: 150%;
  }
}
