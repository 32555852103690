.preview-wrapper {
  mat-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading,
  mat-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch,
  mat-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
    @apply border-light-base-300/90 dark:border-dark-base-300/90;
  }

  mat-form-field .mat-mdc-text-field-wrapper.mdc-text-field--no-label {
    @apply px-3 bg-light-base-0 dark:bg-dark-base-100 rounded-md;
  }

  mat-form-field.mat-mdc-form-field {
    @apply flex;
  }

  .mat-mdc-form-field-subscript-wrapper {
    @apply hidden;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    @apply flex items-center py-2 pl-0 flex-wrap h-[46px];
  }

  input.mat-mdc-input-element {
    @apply pl-0.5 inline-flex flex-grow basis-4;
  }

  app-icon-button button.icon-button.mdc-button.mat-mdc-button.mat-mdc-button-base {
    @apply h-5 w-5 leading-5 mr-0;
  }

  app-icon-button button.mat-mdc-button .mat-icon {
    @apply mr-0;
  }

  .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
    @apply pr-1;
  }

  .mat-mdc-form-field-icon-suffix {
    @apply pr-1.5 py-1.5 pointer-events-none cursor-pointer;
  }

  .mat-mdc-select-arrow-wrapper {
    @apply hidden;
  }

  mat-select-trigger,
  .mat-mdc-select {
    @apply flex;
  }
}
