.tag-selector {
  @apply mt-1 rounded-[4px] #{!important};
  &.mat-mdc-autocomplete-panel {
    @apply bg-light-base-0 dark:bg-dark-base-25 border border-b-0 border-solid border-transparent dark:border-dark-base-300/90;
  }
}
app-tags-input {
  &
    mat-chip-grid
    mat-chip-row.mat-mdc-chip.mat-mdc-chip-row.mdc-evolution-chip
    .mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
    @apply text-inherit pb-0.5;
  }
}
