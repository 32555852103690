//
// Light Theme
//
.user-guide-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.user-guide-content {
  display: block;
  max-width: 1080px;
  margin: 0 auto;
  padding: $padding-xxl;
}
.user-guide-footer {
  height: 400px;
  background: darkolivegreen;
}

//
// Dark Theme
//
