.share-to-employee {
  .group-quick-selection.mat-form-field-appearance-outline {
    @apply w-full;
  }

  .group-quick-selection {
    & .mat-mdc-select-arrow-wrapper {
      @apply hidden;
    }
    &.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
      @apply pr-1;
    }
  }

  .mat-mdc-slide-toggle.mat-disabled {
    @apply opacity-60 #{!important};
  }

  .schedule-sharing {
    & .mat-slide-toggle-label {
      @apply inline-flex flex-nowrap;
    }

    & .schedule-sharing-date .mat-mdc-form-field {
      @apply inline;
    }

    & .schedule-sharing-date {
      & .mat-mdc-icon-button svg,
      .mat-mdc-icon-button img {
        @apply h-5 w-5;
      }
    }

    & .schedule-sharing-time .time {
      @apply flex;

      & app-time-picker {
        @apply flex w-full;
      }

      & .mat-mdc-form-field {
        @apply w-full;
      }
    }

    & button {
      @apply flex items-center justify-center;
    }
  }

  & .time-error {
    & .mat-form-field-outline {
      @apply text-red-700 dark:text-red-300;
    }

    & .mat-form-field-outline-start,
    .mat-form-field-appearance-outline,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      @apply border-2;
    }
  }

  .share-to-employee-table {
    @apply bg-light-base-0 dark:bg-dark-base-0;

    mat-row.mat-mdc-row {
      @apply bg-light-base-0 dark:bg-dark-base-0 hover:bg-[#fafafa] dark:hover:bg-dark-base-25;
    }

    .mat-column-thumbnail {
      @apply pl-4 w-11 max-w-[64px];
    }

    .mat-column-options {
      @apply pr-4 w-12 max-w-[76px] justify-end;
    }

    .mat-column-name {
      @apply pl-0 text-light-base-600 dark:text-dark-base-600;
    }

    .mat-column-group {
      @apply pl-0 pr-0 py-1;
    }

    .mat-row:last-child {
      @apply border-b-0;
    }

    .employee-disabled.employee-not-selected {
      @apply grayscale opacity-75 #{!important};
      &:hover {
        @apply bg-light-base-0 dark:bg-dark-base-0;
      }
    }

    .employee-selected {
      @apply bg-[#f3f8ff] dark:bg-[#171b1f] #{!important};
    }
  }

  .employee-table-paginator {
    @apply text-xs rounded-b-md;

    &.mat-mdc-paginator {
      @apply text-light-base-500 dark:text-dark-base-500 bg-light-base-0 dark:bg-dark-base-0 border-solid border-0 border-t border-t-light-base-200 dark:border-t-dark-base-300;

      & .mdc-text-field--outlined {
        --mdc-outlined-text-field-outline-width: 0px !important;
        --mdc-outlined-text-field-focus-outline-width: 0px !important;
      }

      & .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        @apply border-0 #{!important};
      }

      & .mat-mdc-paginator-container {
        @apply px-4 justify-between;
      }

      & .mat-mdc-select-value {
        @apply text-light-base-500 dark:text-dark-base-500;
      }
    }
  }
}

.group-quick-selection-menu.mat-mdc-select-panel {
  @apply mt-2 rounded-[4px] #{!important};
  &.mat-mdc-autocomplete-panel {
    @apply bg-light-base-0 dark:bg-dark-base-25 border border-b-0 border-solid border-transparent dark:border-dark-base-300/90;
  }
}
