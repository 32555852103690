mat-accordion.mat-accordion.manage-user-roles {
  & mat-expansion-panel.mat-expansion-panel {
    @apply bg-white dark:bg-dark-base-50 border border-solid border-light-base-300/90 dark:border-dark-base-300/90 shadow-none;
  }
  & .mat-expansion-panel-body {
    @apply px-5 pb-5;
  }
  & .mat-expansion-panel-header {
    @apply px-5 py-1;
  }
  .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    @apply bg-light-brand-100 dark:bg-dark-brand-100 text-white dark:text-white shadow-inner;
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    @apply text-[13px];
  }
  mat-button-toggle-group {
    @apply h-9 border border-solid border-light-base-300 dark:border-dark-base-400/10 drop-shadow-sm;
  }
}
