//
// Light Theme
//
.empty-state-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;

  & p {
    color: $LT-secondary-text;
  }

  .empty-state-image {
    height: 180px;
    width: 180px;
    background-size: cover;
    background-repeat: no-repeat;

    &.notifications {
      background-image: url("./../../assets/images/island.png");
    }
    &.scheduled {
      background-image: url("./../../assets/images/desertscape.png");
    }
    &.composer {
      background-image: url("./../../assets/images/desertscape.png");
    }
  }
}

.empty-state-text {
  font-size: 14px;
  font-weight: 500;
  color: $LT-primary-text;
  text-align: center;
}

//
// Dark Theme
//

.dark {
  .empty-state-wrapper {
    & p {
      color: $DK-secondary-text;
    }

    .empty-state-image {
      &.notifications {
        background-image: url("./../../assets/images/camping.png");
      }
      &.scheduled {
        background-image: url("./../../assets/images/desertscape.png");
      }
      &.composer {
        background-image: url("./../../assets/images/desertscape.png");
      }
    }
  }

  .empty-state-text {
    color: $DK-primary-text;
  }
}
