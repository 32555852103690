@use "@angular/material" as mat;

@include mat.core();

$app-typography-config: mat.define-typography-config(
  $font-family:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  $headline-1: mat.define-typography-level(112px, 112px, 300),
  $headline-2: mat.define-typography-level(56px, 56px, 400),
  $headline-3: mat.define-typography-level(45px, 48px, 400),
  $headline-4: mat.define-typography-level(34px, 40px, 400),
  $headline-5: mat.define-typography-level(24px, 32px, 400),
  $headline-6: mat.define-typography-level(20px, 32px, 500),
  $subtitle-1: mat.define-typography-level(16px, 28px, 400),
  $subtitle-2: mat.define-typography-level(15px, 24px, 400),
  $body-1: mat.define-typography-level(14px, 20px, 400),
  $body-2: mat.define-typography-level(14px, 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
);

$mat-primary: (
  main: #1772e8,
  lighter: #b9d5f8,
  darker: #0d55de,
  contrast: (
    main: #1772e8,
    lighter: #b9d5f8,
    darker: #0d55de,
  ),
);
$light-theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-primary-warning: (
  main: #b91c1c,
  lighter: #e44a4a,
  darker: #991b1b,
  contrast: (
    main: #b91c1c,
    lighter: #e44a4a,
    darker: #991b1b,
  ),
);
$light-theme-warning: mat.define-palette($mat-primary-warning, main, lighter, darker);

$mat-primary-dark: (
  main: #478eed,
  lighter: #75aaf1,
  darker: #166de0,
  contrast: (
    main: #478eed,
    lighter: #75aaf1,
    darker: #166de0,
  ),
);
$dark-theme-primary: mat.define-palette($mat-primary-dark, main, lighter, darker);

$mat-primary-dark-warning: (
  main: #f87171,
  lighter: #fca5a5,
  darker: #f10c0c,
  contrast: (
    main: #f87171,
    lighter: #fca5a5,
    darker: #f10c0c,
  ),
);
$dark-theme-warning: mat.define-palette($mat-primary-dark-warning, main, lighter, darker);

// Define a light theme
$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $light-theme-primary,
      accent: $light-theme-primary,
      warn: $light-theme-warning,
    ),
    typography: $app-typography-config,
    density: -3,
  )
);

// Define a dark theme
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-theme-primary,
      accent: $dark-theme-primary,
      warn: $dark-theme-warning,
    ),
  )
);

@include mat.all-component-themes($light-theme);

.dark {
  @include mat.all-component-colors($dark-theme);
}
