//
// Light Theme
//
.card-button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  & .member-button.mat-button-toggle-appearance-standard {
    border-radius: 50%;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  & .member-button.mat-button-toggle-checked mat-icon {
    color: #fff;
  }

  & .member-button {
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    & .mat-button-toggle-label-content {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 0;
      line-height: 0;
      font-size: 0;
      height: 32px;
      width: 32px;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    &.home .mat-button-toggle-label-content {
      height: 40px;
      width: 40px;
    }

    &:only-child {
      margin: 0 auto;
    }
  }
}

//
// Dark Theme
//
.dark .card-button-group {
}
