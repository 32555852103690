//
// Dark Theme
//
app-time-selector {
  & app-time-picker {
    & .mat-mdc-text-field-wrapper {
      padding-bottom: 0;
      & .mat-mdc-form-field-infix {
        width: 100%;

        & input {
          width: auto;
          min-width: 0;
          max-width: 24px;
          text-align: center;
        }
      }
    }
  }
}

.clock-hours,
.clock-minutes {
  margin-top: 8px;
  display: flex;
  position: relative;
  height: 190px;
  width: 190px;
  background-color: $LT-card;
  border-radius: $border-radius-sm;
  box-shadow:
    0 0 0 1px rgba(136, 152, 170, 0.1),
    0 15px 35px 0 rgba(49, 49, 93, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.13);
}

.clock-container {
  width: 180px;
  height: 180px;
  margin: 5px;
  border-radius: 50%;
  background-color: $LT-background;
}

.clock-container ul {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;

  & li {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size: 13px;
    top: 76px;
    left: 76px;
    background-color: $LT-card;
    border: 1px solid $LT-border;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    list-style: none;
    padding: 0;
    margin: 0;
    & span {
      pointer-events: none;
    }
    &:hover {
      cursor: pointer;
      background-color: $LT-brand-primary;
      color: white;
    }
    /* 12em = half the width of the wrapper */
    &.hour-12,
    &.minute-0 {
      transform: translate(0, -66px);
    }
    &.hour-1,
    &.minute-5 {
      transform: rotate(30deg) translate(0, -66px) rotate(-30deg);
    }
    &.hour-2,
    &.minute-10 {
      transform: rotate(60deg) translate(0, -66px) rotate(-60deg);
    }
    &.hour-3,
    &.minute-15 {
      transform: rotate(90deg) translate(0, -66px) rotate(-90deg);
    }
    &.hour-4,
    &.minute-20 {
      transform: rotate(120deg) translate(0, -66px) rotate(-120deg);
    }
    &.hour-5,
    &.minute-25 {
      transform: rotate(150deg) translate(0, -66px) rotate(-150deg);
    }
    &.hour-6,
    &.minute-30 {
      transform: rotate(180deg) translate(0, -66px) rotate(-180deg);
    }
    &.hour-7,
    &.minute-35 {
      transform: rotate(210deg) translate(0, -66px) rotate(-210deg);
    }
    &.hour-8,
    &.minute-40 {
      transform: rotate(240deg) translate(0, -66px) rotate(-240deg);
    }
    &.hour-9,
    &.minute-45 {
      transform: rotate(270deg) translate(0, -66px) rotate(-270deg);
    }
    &.hour-10,
    &.minute-50 {
      transform: rotate(300deg) translate(0, -66px) rotate(-300deg);
    }
    &.hour-11,
    &.minute-55 {
      transform: rotate(330deg) translate(0, -66px) rotate(-330deg);
    }
  }
}

.hands {
  background: $LT-border;
  height: 40%;
  left: calc(50% - 1px);
  position: absolute;
  top: 10%;
  transform-origin: 50% 100%;
  width: 2px;
  transform: rotate(0deg);
  &.hover-1,
  &.hover-m-5 {
    transform: rotate(30deg);
  }
  &.hover-2,
  &.hover-m-10 {
    transform: rotate(60deg);
  }
  &.hover-3,
  &.hover-m-15 {
    transform: rotate(90deg);
  }
  &.hover-4,
  &.hover-m-20 {
    transform: rotate(120deg);
  }
  &.hover-5,
  &.hover-m-25 {
    transform: rotate(150deg);
  }
  &.hover-6,
  &.hover-m-30 {
    transform: rotate(180deg);
  }
  &.hover-7,
  &.hover-m-35 {
    transform: rotate(210deg);
  }
  &.hover-8,
  &.hover-m-40 {
    transform: rotate(240deg);
  }
  &.hover-9,
  &.hover-m-45 {
    transform: rotate(270deg);
  }
  &.hover-10,
  &.hover-m-50 {
    transform: rotate(300deg);
  }
  &.hover-11,
  &.hover-m-55 {
    transform: rotate(330deg);
  }
}

.clock-dot {
  display: block;
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - 3px);
  width: 6px;
  height: 6px;
  background-color: $LT-brand-primary;
  border-radius: 50%;
  z-index: 10050;
}

//
// Dark Theme
//
.dark {
  .clock-hours,
  .clock-minutes {
    background-color: $DK-card;
    box-shadow:
      0 0 0 1px rgba(136, 152, 170, 0.1),
      0 15px 35px 0 rgba(49, 49, 93, 0.1),
      0 5px 15px 0 rgba(0, 0, 0, 0.13);
  }

  .clock-container {
    background-color: $DK-background;
  }

  .clock-container ul {
    & li {
      color: rgba(256, 256, 256, 0.8);
      background-color: $DK-card;
      border: 1px solid $DK-border;
      &:hover {
        background-color: $DK-brand-primary;
        color: white;
      }
    }
  }

  .hands {
    background: $DK-border;
  }

  & .clock-dot {
    background-color: $DK-brand-primary;
  }
}
