.employee-groups-list {
  @apply w-80 mt-1;
  & .mat-mdc-selection-list {
    @apply pt-0;
  }
  & .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
    @apply mx-1;
  }
  & .mat-mdc-menu-content {
    @apply pb-0;
  }
  & .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line {
    @apply h-9 pr-3;
  }
  .mdc-list {
    @apply pb-0;
  }
}

.employee-token-tracker {
  @apply flex justify-center;

  & .token-tracker-wrapper {
    @apply relative h-6 w-6 m-1;
  }

  & .mat-mdc-progress-spinner {
    @apply absolute inset-0;
  }

  & .token-track.mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    @apply text-light-base-100 dark:text-dark-base-100 stroke-current #{!important};
  }

  & .token-left.mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    @apply text-light-success-100 dark:text-dark-success-100 stroke-current #{!important};
  }

  & .token-expired.mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    @apply text-red-700 dark:text-red-300 stroke-current #{!important};
  }
}

.pill-active .mat-icon {
  @apply text-white;
}

.employee-list {
  mat-cell,
  mat-header-cell {
    @apply self-stretch box-content font-medium text-xs;
  }

  .mat-mdc-cell,
  .mat-header-cell {
    @apply border-b-light-base-200 dark:border-b-dark-base-400/10;
  }

  @apply bg-white dark:bg-dark-base-100;

  .employee-table {
    @apply min-w-[800px];
  }

  .mat-column-select {
    @apply max-w-[40px] pr-0 pl-3 #{!important};
  }

  .mat-column-name {
    @apply min-w-[200px] max-w-[320px] text-[13px] font-medium px-2;
  }

  .mat-column-email {
    @apply min-w-[180px] max-w-[320px] px-2;
  }

  .mat-column-token {
    @apply max-w-[80px];
  }

  .mat-column-client {
    @apply max-w-[100px];
  }

  .mat-column-options {
    @apply justify-end pr-3 #{!important};
  }

  .mat-mdc-paginator {
    @apply text-light-base-500 dark:text-dark-base-500 bg-light-base-0 dark:bg-dark-base-0 border-solid border-0 border-t border-t-light-base-200 dark:border-t-dark-base-300;

    & .mdc-text-field--outlined {
      --mdc-outlined-text-field-outline-width: 0px !important;
      --mdc-outlined-text-field-focus-outline-width: 0px !important;
    }

    & .mat-mdc-paginator-container {
      @apply px-4 justify-between;
    }
  }

  .mat-mdc-paginator-page-size-label {
    @apply hidden sm:block #{!important};
  }

  .employee-table-filter {
    .mat-mdc-chip-set.mat-mdc-chip-grid {
      @apply flex items-center mr-2;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-outline {
      @apply text-transparent m-0 #{!important};
    }

    .mat-mdc-form-field-flex .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        @apply border-0 #{!important};
      }
    }

    mat-form-field,
    .mat-mdc-form-field-infix {
      @apply flex h-12.5;
    }

    .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
      @apply m-0 flex-grow;
    }

    .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
      @apply hidden;
    }

    .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
      @apply p-0;
    }

    input.mat-mdc-chip-input {
      @apply h-16 m-0;
    }

    .mat-mdc-chip {
      @apply py-0.5;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-flex {
      @apply mt-0 p-0;
    }

    input.mat-mdc-chip-input::placeholder {
      @apply opacity-0;
    }

    .mat-focused input.mat-mdc-chip-input::placeholder,
    .has-filters input.mat-mdc-chip-input::placeholder {
      @apply opacity-100;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
    .mat-chip-list-wrapper {
      @apply flex-row;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-outline {
      @apply top-0;
    }

    .mat-mdc-text-field-wrapper {
      @apply pb-0 px-5 #{!important};
    }
  }

  mat-row.mat-mdc-row {
    @apply bg-white dark:bg-dark-base-0 hover:bg-[#fafafa] dark:hover:bg-dark-base-25;
  }

  .mat-mdc-header-row,
  mat-header-row {
    @apply min-h-[48px] bg-light-base-50 dark:bg-dark-base-25 #{!important};
  }

  .mat-mdc-header-cell {
    @apply border border-t-0 border-b border-l-0 border-r-0 border-solid border-light-base-200 dark:border-dark-base-300;
  }

  .mat-mdc-no-data-row {
    @apply flex items-center justify-center min-h-[48px] h-12 text-light-base-400 dark:text-dark-base-400 bg-light-base-0 dark:bg-dark-base-0 #{!important};
  }

  .mat-mdc-row:last-child {
    @apply border-b-0;
  }

  .employee-selected {
    @apply bg-[#f3f8ff] dark:bg-[#171b1f] #{!important};
  }

  mat-header-row,
  mat-header-row.mat-table-sticky-border-elem-top {
    @apply border border-t border-b-0 border-l-0 border-r-0 border-solid border-light-base-200 dark:border-dark-base-300 #{!important};
  }
}

.employee-table-column-menu {
  .mat-mdc-menu-content {
    @apply min-w-[220px];
  }

  .mat-mdc-menu-item {
    @apply leading-[40px] h-10 px-2 min-h-[40px];
  }
}

.export-employees-loader {
  @apply h-5 w-5 #{!important};
}

.employee-table-loading {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: indeterminate-progress-bar;
}

@keyframes indeterminate-progress-bar {
  from {
    left: -50%;
  }
  to {
    left: 150%;
  }
}
