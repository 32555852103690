.preview-dialog {
  padding: $padding-xl $padding-lg;

  & .mat-mdc-tab-nav-bar,
  & .mat-mdc-tab-header {
    border-bottom: 0;
    padding: $padding-xxs;
  }

  & .hidden-tab-header {
    & .mat-mdc-tab-header {
      display: none !important;
    }
  }

  & mat-ink-bar {
    display: none;
  }

  & .mat-mdc-tab-header {
    background: $LT-card;
    border: 1px solid $LT-border;
    border-radius: $border-radius-sm;
    margin-right: $margin-xs;
    margin-left: $margin-xs;
    margin-bottom: $margin-xl;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  &.mat-tab-labels {
    padding: $padding-xxs;
    justify-content: space-around;
  }

  & mat-tab-body {
    overflow: hidden !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  & .mat-tab-body-content {
    overflow: hidden !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  & .mat-tab-label {
    flex: 1;
    height: 34px;
    padding: 0 $padding-md;
    text-align: center;
    justify-content: center;
    opacity: 1;
    border-radius: $border-radius-sm;
    min-width: 0;
    color: $LT-secondary-text;

    & .mat-icon {
      height: 16px !important;
      width: 16px !important;
      line-height: 16px !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    &.mat-tab-label-active {
      background: $LT-brand-primary;
      color: white;
      opacity: 1;

      & .mat-icon {
        color: white !important;
        height: 16px !important;
        width: 16px !important;
        line-height: 16px !important;
      }
    }
  }
}

.dark .preview-dialog {
  & .mat-mdc-tab-header {
    background: $DK-card;
    border: 1px solid $DK-border;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  & .mat-tab-label {
    color: $DK-secondary-text;
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    &.mat-tab-label-active {
      background: $DK-brand-primary;
      color: white;

      & .mat-icon {
        color: white !important;
      }
    }
  }
}
