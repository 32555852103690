.calendar-settings {
  & mat-form-field {
    & .mat-mdc-text-field-wrapper.mdc-text-field {
      @apply pr-1 #{!important};
    }

    & .mat-mdc-select-arrow-wrapper {
      @apply hidden;
    }
  }
}
