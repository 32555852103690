//
// Light Theme
//
.ql-container.ql-snow {
  border: 0;
  background-color: $LT-card;
  padding: 0;
  font-size: 16px;
  line-height: 1.666666em;
  margin-bottom: 20px;
}

.editor-wrapper {
  @apply border-solid border border-light-base-300/90 dark:border-dark-base-300/90 bg-light-base-0 dark:bg-dark-base-50;

  &:hover {
    @apply border-light-base-600 dark:border-dark-base-600;
  }

  .has-errors &,
  .facebook-has-errors &,
  .twitter-has-errors &,
  .instagram-has-errors &,
  .linkedin-has-errors & {
    @apply border-solid border-2 border-light-danger-100 dark:border-dark-danger-100 #{!important};
  }

  .has-errors & {
    margin: -1px;
  }
}

.has-errors.error-override .editor-wrapper {
  border: 1px solid;
  border-color: $LT-border;
  margin: 0;
}

.editor,
.editor-comment {
  min-height: 120px;
  position: relative;
  padding: 16px 16px 0 16px;
  background-color: $LT-card;
  box-sizing: border-box;
  will-change: height;

  & quill-editor {
    @apply block;
  }

  & .ql-editor {
    @apply overflow-hidden caret-light-brand-100 dark:caret-dark-brand-100 text-light-base-600 dark:text-dark-base-600;
  }

  .collapsed & {
    overflow: hidden;
    height: 48px;
    max-height: 48px;
    min-height: 48px;

    & .ql-editor {
      display: -webkit-box !important;
      -webkit-line-clamp: 1 !important;
      -webkit-box-orient: vertical !important;
      overflow: hidden;
    }

    .reminder {
      display: none;
    }
  }

  .collapsed.with-pics & {
    height: 90px;
    min-height: 90px;
    max-height: 90px;
  }
}

.editor-actions {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  margin: 16px;
  box-sizing: border-box;
  border: 1px solid $LT-border;
  background: $LT-card;
  border-radius: $border-radius-sm;

  & > * {
    display: flex;
  }

  .action-wrapper,
  .action {
    display: inline-flex;
    cursor: pointer;
    align-items: center;

    &.active {
      background-color: $LT-border-light;
      border-radius: $border-radius-sm;
      padding: 3px;
      margin-right: 10px;
      color: $LT-primary-text;

      .action {
        margin: 0;
      }

      .close {
        display: inline-flex;
        width: 14px;
        height: 14px;
        padding: $padding-xxs;
        margin-left: $margin-sm;
        border-radius: 50%;
        opacity: 0.75;

        &:hover {
          background: $LT-background;
          opacity: 1;
        }
      }
    }
  }

  .action {
    margin-right: 10px;

    mat-icon {
      color: $LT-secondary-text;
      width: 20px;
      height: 20px;
    }

    &:hover {
      mat-icon {
        color: $LT-primary-text;
      }
    }

    &.attach-media input {
      display: none;
    }
  }

  .close {
    display: none;
  }

  .collapsed & {
    display: none;
  }
}

.ql-editor {
  padding: 0;

  .collapsed & {
    max-height: 20px;
  }

  .collapsed.with-pics & {
    height: 50px;
  }

  &.ql-blank {
    &::before {
      left: 0;
      font-style: normal;
      color: $LT-tertiary-text;
    }
  }
}

.reminder {
  display: inline-flex;
  font-size: 12px;
  color: $LT-tertiary-text;
  margin-left: -3px;

  & .reminder-icon {
    align-self: flex-start;

    & mat-icon {
      color: $LT-tertiary-text;
    }
  }

  & .reminder-text {
    align-self: flex-start;
    white-space: normal;
  }
}

.images {
  grid-auto-flow: dense;
  grid-auto-rows: 86px;
  grid-template-columns: 86px 86px 86px 86px;

  .image {
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
    border-radius: $border-radius-sm;
    overflow: hidden;

    &.gallery-one {
      grid-column: span 4;
      grid-row: span 2;
    }

    &.gallery-two {
      grid-column: span 2;
      grid-row: span 2;
    }

    &.gallery-three {
      &:first-child {
        grid-column: span 2;
        grid-row: span 2;
      }

      &:nth-child(2) {
        grid-column: span 2;
        grid-row: span 1;
      }

      &:nth-child(3) {
        grid-column: span 2;
        grid-row: span 1;
      }
    }

    &.gallery-four {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        grid-column: span 2;
        grid-row: span 1;
      }
    }

    &.gallery-five {
      grid-column: span 1;
      grid-row: span 1;

      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        grid-column: span 2;
        grid-row: span 1;
      }
    }

    &.gallery-six {
      grid-column: span 1;
      grid-row: span 1;

      &:first-child,
      &:nth-child(3) {
        grid-column: span 2;
        grid-row: span 1;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid $LT-border;
      border-radius: $border-radius-sm;
      box-sizing: border-box;

      &.validation-error {
        border: 2px solid;
        border-color: $LT-warn-primary;
      }
    }

    .close {
      position: absolute;
      top: 5px;
      right: 5px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }

      mat-icon {
        color: white;
      }
    }

    .edit {
      display: inline-flex;
      position: absolute;
      top: 5px;
      left: 5px;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 34px;
      border-radius: $border-radius-sm;
      font-size: 12px;
      font-weight: 500;
      color: $DK-primary-text;
      background-color: rgba(0, 0, 0, 0.7);
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }

    .loading {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;

      & mat-icon {
        height: 40px;
        width: 40px;
      }

      & .spinner .path {
        stroke: $DK-primary-text !important;
      }
    }
  }

  .collapsed & {
    position: absolute;
    top: 30px;
    left: 12px;
    height: 30px;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-auto-rows: 30px;
    row-gap: 3px;
    column-gap: 3px;

    .image {
      width: 30px;
      height: 30px;

      & .loading app-loader {
        height: 16px !important;
        width: 16px !important;
      }

      .close,
      .edit {
        display: none;
      }

      &.gallery-one,
      &.gallery-two,
      &.gallery-three,
      &.gallery-four,
      &.gallery-five,
      &.gallery-six {
        grid-column: span 1;
        grid-row: span 1;
      }
    }
  }
}

.ql-mention-list-container {
  background-color: $LT-card;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border: 0;
}

.ql-mention-list {
  border-radius: $border-radius-sm;
  padding: $padding-xs;
}

.mention,
.mention > span,
.ql-mention-denotation-char {
  background: transparent !important;
  border-radius: 0 !important;
  margin: 0 !important;
  color: $LT-brand-primary !important;
}

.ql-mention-list-item {
  @apply h-10 leading-10 rounded-sm whitespace-nowrap text-sm py-0 px-1;

  & img {
    @apply rounded-full mr-2.5 h-7 w-7;
  }

  &.selected {
    background: $LT-hover;

    &:first-child {
      border-radius: $border-radius-sm $border-radius-sm 0 0;
    }
  }

  & .cql-list-item-inner {
    display: flex;
    align-items: center;
  }
}

//
// Dark Theme
//
.dark {
  & .ql-container.ql-snow {
    background-color: $DK-card;
  }

  .has-errors.error-override .editor-wrapper {
    border: 1px solid;
    border-color: $DK-border;
  }

  & .editor,
  & .editor-comment {
    background-color: $DK-card;
  }

  .editor-actions {
    border: 1px solid $DK-border;
    background: $DK-card;

    .action-wrapper,
    .action {
      &.active {
        background-color: $DK-border-light;
        color: $DK-primary-text;

        .close {
          &:hover {
            background: $DK-background;
          }
        }
      }
    }

    .action {
      mat-icon {
        color: $DK-secondary-text;
      }

      &:hover {
        mat-icon {
          color: $DK-primary-text;
        }
      }
    }
  }

  .ql-editor {
    &.ql-blank {
      &::before {
        color: $DK-tertiary-text;
      }
    }
  }

  .images {
    .image {
      img {
        border: 1px solid $DK-border;

        &.validation-error {
          border-color: $DK-warn-primary;
        }
      }

      .close {
        background-color: rgba(0, 0, 0, 0.5);

        &:hover {
          background-color: rgba(0, 0, 0, 0.8);
        }
      }

      .loading {
        & .spinner .path {
          stroke: $DK-primary-text !important;
        }
      }
    }
  }

  .device-list-user {
    & .user {
      color: $DK-secondary-text;
    }

    & .devices {
      /*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
      & .mat-list-item-content {
        /*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
        & .mat-list-text {
          color: $DK-primary-text !important;
        }

        & .mat-pseudo-checkbox {
          color: $DK-border !important;
        }
      }

      & .no-device {
        & .learn-more {
          & mat-icon {
            color: $DK-brand-primary;
            margin-left: $margin-xxs;
          }

          &:hover {
            & mat-icon {
              color: $DK-brand-light;
            }
          }
        }
      }
    }
  }

  .ql-mention-list-container {
    background-color: $DK-card;
  }

  .ql-mention-list-item {
    white-space: nowrap;

    &.selected {
      background: $DK-hover;

      &:first-child {
        border-radius: $border-radius-sm $border-radius-sm 0 0;
      }
    }
  }

  .mention,
  .mention > span,
  .ql-mention-denotation-char {
    background: transparent !important;
    border-radius: 0 !important;
    margin: 0 !important;
    color: $DK-brand-primary !important;
  }

  .reminder {
    color: $DK-tertiary-text;

    & .reminder-icon {
      & mat-icon {
        color: $DK-tertiary-text;
      }
    }
  }
}

.video-attachment {
  .collapsed & {
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    background-image: url(./../../assets/icons/play-arrow-white.svg);
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.ql-hidden {
  display: none !important;
}
