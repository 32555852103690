$channel-selection-wrapper-width: 672px;
$channel-selection-wrapper-height: 280px;

//
// Light theme
//
.app-dialog {
  @apply text-sm m-7 overflow-hidden;

  & .mat-mdc-dialog-container {
    @apply rounded-md overflow-hidden bg-transparent dark:bg-transparent;

    & .mdc-dialog__title {
      &:before {
        @apply h-auto;
      }
    }
  }

  &.photo-editor-dialog {
    position: relative !important;
    border-radius: $border-radius-sm;

    & .photo-editor,
    & app-photo-editor {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: $border-radius-sm;
      overflow: hidden;

      & .pintura-editor {
        --color-primary: #1772e8;

        & .PinturaButtonExport,
        & .PinturaInputFormButtonConfirm {
          color: white;

          &:hover {
            background-color: #1772e8 !important;
            color: white !important;
          }
        }
      }
    }
  }

  &.zapier-iframe {
    border-radius: $border-radius-sm;
    overflow: hidden;

    & .mat-mdc-dialog-container {
      position: relative;
      padding: 0 !important;
    }
  }

  &.add-channel .mat-mdc-dialog-component-host {
    @apply p-6;
  }

  &.add-channel,
  &.invite-users,
  &.single-action {
    min-width: 532px;
    max-height: 90vh;

    & .mat-mdc-dialog-component-host {
      background: $LT-background;
      overflow: hidden;
    }

    & .dialog-wrapper-col {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & .dialog-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    & .mat-mdc-tab-header {
      display: none;
    }

    & .channel-search-field {
      width: 100%;
      position: relative;

      & input {
        width: calc(100% - 150px);
      }
    }

    .at-limit {
      margin: 0 auto;
    }

    .select-channels {
      overflow: hidden;
      border: 1px solid;
      border-color: $LT-border-light;
      border-radius: $border-radius-sm;
      width: $channel-selection-wrapper-width;
      max-height: $channel-selection-wrapper-height;

      & .app-scrollbar {
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 148px;
        max-height: $channel-selection-wrapper-height;
      }
    }

    & .channel-list {
      max-height: $channel-selection-wrapper-height;
    }

    & .selected-channels {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      border: 1px solid;
      border-color: $LT-border-light;
      border-radius: $border-radius-sm;
      padding: $padding-sm;
      margin-top: 10px;
      max-width: 674px;
      box-sizing: border-box;
    }

    & .searching-public {
      position: relative;
      border: 1px solid;
      border-color: $LT-border-light;
      border-radius: $border-radius-sm;
      width: $channel-selection-wrapper-width;
      height: $channel-selection-wrapper-height;
      margin-top: $margin-xl;
      overflow: hidden;

      & app-loader {
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
      }

      & .placeholder-wrapper {
        min-height: $channel-selection-wrapper-height;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius-sm;

        & .placeholder {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 208px;
          width: 148px;
          font-size: 12px;
          border: 2px dashed;
          color: $LT-secondary-text;
          border-color: $LT-border;
          border-radius: $border-radius-sm;
          margin: 10px;
          box-sizing: border-box;
          background: $LT-background;
          z-index: $z-cards;

          & mat-icon {
            color: $LT-tertiary-text;
          }
        }
      }
    }

    .search-options {
      display: flex;
      flex-direction: row;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10000;

      & app-icon-button {
        opacity: 0.3;
        position: relative;
        filter: grayscale(100);

        &.active {
          opacity: 1;
          filter: none;

          &:hover {
            opacity: 1;
          }
        }

        &:hover {
          opacity: 0.5;
        }

        /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        & .mat-button-disabled {
          cursor: not-allowed;
        }
      }
    }

    & .dialog-footer {
      margin-top: $margin-xxl;
      justify-content: space-between;

      & .save-selected {
        width: 30%;
        text-align: right;
      }
    }

    &.channel-limit {
      min-width: 400px;
      max-width: 400px;

      & .mat-mdc-dialog-container {
        padding: 40px;
      }
    }
  }

  &.add-channel {
    min-width: unset;
  }

  .rocket {
    padding: 30px;
    background: $LT-card;
    border-radius: 50%;
    height: 40px;
  }

  &.invite-users {
    & .dialog-footer {
      margin-top: $margin-xxl;
    }
  }

  & .dialog-header {
    margin-bottom: $margin-xl;
    position: relative;
  }

  & .dialog-title {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: $margin-xs;
    color: $LT-primary-text;
  }

  & .dialog-subtitle {
    text-align: center;
    color: $LT-secondary-text;
  }

  & .colors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border: 2px solid transparent;
    border-radius: $border-radius-sm;
    max-height: 260px;
    overflow-x: hidden;
    overflow-y: auto;

    &.error {
      border-color: $LT-warn-primary;
    }
  }

  & .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $margin-xl;

    & .hint {
      display: flex;
      align-items: center;
      color: $LT-secondary-text;

      & mat-icon {
        margin-right: $margin-sm;
      }
    }
  }
}

.image-preview-dialog {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

//
// Dark theme
//
.dark .app-dialog {
  &.photo-editor-dialog {
    & .photo-editor,
    & app-photo-editor {
      & .pintura-editor {
        --color-background: 0, 0, 0;
        --color-foreground: 255, 255, 255;
        --color-primary: #2f80e7;

        & .PinturaButtonExport,
        & .PinturaInputFormButtonConfirm {
          color: white;

          &:hover {
            background-color: #2f80e7 !important;
            color: white !important;
          }
        }
      }
    }
  }

  &.add-channel,
  &.schedule-dialog,
  &.invite-users,
  &.single-action {
    & .mat-mdc-dialog-component-host {
      background: $DK-background;
    }

    .select-channels {
      border-color: $DK-border-light;
      border-radius: $border-radius-sm;
    }

    & .selected-channels {
      border-color: $DK-border-light;
    }

    & .searching-public {
      border-color: $DK-border-light;

      & .placeholder-wrapper {
        & .placeholder {
          border-color: $DK-border;
          background: $DK-background;
          color: $DK-secondary-text;

          & mat-icon {
            color: $DK-tertiary-text;
          }
        }
      }
    }

    .search-options {
      & app-icon-button {
        &.instagram-channel {
          opacity: 0.5;

          &:hover {
            opacity: 0.7;
          }
        }

        &.active.instagram-channel {
          opacity: 1;
        }
      }
    }
  }

  & .rocket {
    background: $DK-card;
  }

  & .dialog-title {
    color: $DK-primary-text;
  }

  & .dialog-subtitle {
    color: $DK-secondary-text;
  }

  & .colors {
    &.error {
      border-color: $DK-warn-primary;
    }
  }

  & .dialog-footer {
    & .hint {
      color: $DK-secondary-text;
    }
  }
}
