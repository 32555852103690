.employee-group-filter div {
  @apply lowercase m-1 flex-1 inline-flex items-center font-medium py-1.5 px-3 rounded-3xl whitespace-nowrap cursor-pointer select-none text-light-base-400 dark:text-dark-base-400 bg-light-base-200 dark:bg-dark-base-200;

  & .count {
    @apply bg-white dark:bg-dark-base-100 #{!important};
  }

  &:hover {
    @apply opacity-80 #{!important};
  }

  &.active {
    @apply bg-light-brand-100 dark:bg-dark-brand-100 opacity-100 text-white #{!important};
  }
}
