.settings {
  & .settings-nav {
    display: block;
    margin-top: 18px;

    & .mat-expansion-panel {
      box-shadow: none;
      background: transparent;
      padding: 0;
      margin: 0 0 4px 0;

      & .mat-expansion-panel-header {
        padding: 0;
        height: 32px;
        background: transparent !important;

        &.mat-expanded {
          height: 32px;
        }

        & .mat-content {
          height: 40px;

          & .mat-expansion-panel-header-title {
            height: 32px;
          }
        }

        & .mat-expansion-indicator {
          height: 32px;
        }
      }

      & .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }

  .remove-scroll {
    & .viewclass-scrollbar {
      @apply overflow-hidden #{!important};
    }
  }
}
