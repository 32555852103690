app-activity.graph-activity {
  & > div {
    @apply border-0 #{!important};
  }
  @apply shadow-none m-0;

  & .channel app-profile-picture img {
    @apply h-8 w-8 #{!important};
  }

  & .channel .channel-info,
  & .channel .activity-date {
    @apply text-xs #{!important};
  }

  & app-activity-header .activity-header {
    @apply p-1.5 #{!important};
  }

  & .activity-text {
    @apply text-light-base-600 dark:text-dark-base-600  mt-1.5 text-xs line-clamp-3 #{!important};
  }

  & app-link-preview .link {
    @apply bg-transparent #{!important};
    & .link-metadata {
      @apply p-1.5 #{!important};

      & .link-title {
        @apply text-xs #{!important};
      }
    }

    & .link-image {
      max-height: 160px !important;
      padding-bottom: calc(3.05 / 5 * 100%) !important;
    }
  }

  & app-image-attachment {
    & .gallery {
      max-height: 172px !important;

      .photo {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      &.single {
        & .first {
          position: relative;
          grid-area: 1 / 1 / 3 / 3;

          & .photo {
            position: relative !important;
          }
        }
      }

      &.double {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;

        & .first,
        & .second {
          position: relative;
          padding-bottom: calc(5.2 / 3 * 100%) !important;
        }

        & .first {
          grid-area: 1 / 1 / 2 / 2;
        }

        & .second {
          grid-area: 1 / 2 / 2 / 3;
        }
      }

      &.triple {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;

        & .first,
        & .second,
        & .third {
          position: relative;
          padding-bottom: 100%;
        }

        & .first {
          grid-area: 1 / 1 / 2 / 3;
          padding-bottom: calc(3 / 5 * 100%) !important;
        }

        & .second {
          grid-area: 2 / 1 / 3 / 2;
          padding-bottom: calc(2.5 / 5 * 100%) !important;
        }

        & .third {
          grid-area: 2 / 2 / 3 / 3;
          padding-bottom: calc(2.5 / 5 * 100%) !important;
        }
      }

      &.quad {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;

        & .first,
        & .second,
        & .third,
        & .fourth {
          position: relative;
          padding-bottom: 100%;
        }

        & .first {
          grid-area: 1 / 1 / 2 / 2;
          padding-bottom: calc(4.3 / 5 * 100%) !important;
        }

        & .second {
          grid-area: 1 / 2 / 2 / 3;
          padding-bottom: calc(4.3 / 5 * 100%) !important;
        }

        & .third {
          grid-area: 2 / 1 / 3 / 2;
          padding-bottom: calc(4.3 / 5 * 100%) !important;
        }

        & .fourth {
          grid-area: 2 / 2 / 3 / 3;
          padding-bottom: calc(4.3 / 5 * 100%) !important;
        }
      }

      &.quint {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto;

        & .first,
        & .second,
        .third,
        & .fourth,
        & .fifth {
          position: relative;
          padding-bottom: 100%;
        }

        & .first {
          grid-area: 1 / 1 / 4 / 4;
          padding-bottom: calc(5.3 / 5 * 100%) !important;
        }

        & .second {
          grid-area: 1 / 4 / 4 / 7;
          padding-bottom: calc(5.3 / 5 * 100%) !important;
        }

        & .third {
          grid-area: 4 / 1 / 6 / 3;
        }

        & .fourth {
          grid-area: 4 / 3 / 6 / 5;
        }

        & .fifth {
          grid-area: 4 / 5 / 6 / 7;
          position: relative;

          & .more {
            display: flex;
            font-size: 22px;
            font-weight: 500;
            color: white;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            align-items: center;
            justify-content: center;
            background-color: rgba(black, 0.3);
            pointer-events: none;
          }
        }
      }
    }
  }

  & app-video-attachment {
    & video {
      @apply max-h-[174px] #{!important};
    }
  }
}

.base-table-widget.employee-advocacy-top-content-cards {
  & .app-scrollbar.ng-scrollbar .ng-scroll-content {
    @apply flex #{!important};
  }
}
