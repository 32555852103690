.user-menu {
  @apply absolute bottom-2.5 left-12 min-w-[240px] origin-bottom-left #{!important};

  & a.mat-mdc-menu-item {
    @apply flex
    items-center
    text-light-base-600
    hover:text-light-base-600
    dark:text-dark-base-600
    dark:hover:text-dark-base-600
    min-h-[44px];
    & .mat-mdc-menu-item-text {
      @apply flex items-center;
    }
  }

  & .mat-menu-item {
    height: 44px;
    line-height: 44px;
  }
}

.user-avatar.user-thumbnail-medium {
  @apply border-0 shadow-none #{!important};
}

.thumbnail button {
  @apply border-0 shadow-none #{!important};
}

.icon.publisher mat-icon.mat-icon {
  @apply -mt-px;
}

app-icon-button.compose {
  & button.mat-mdc-button.icon.button.icon-button {
    @apply h-10 w-10 #{!important};
    & mat-icon.mat-icon {
      @apply text-light-base-600 dark:text-white;
    }
  }
}
