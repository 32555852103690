.zapier-tabs {
  @apply bg-transparent;

  & .mat-mdc-tab-nav-bar,
  & .mat-mdc-tab-header {
    @apply border-b-0
    -mb-px
    z-50;
  }

  & .mdc-tab-indicator {
    @apply hidden;
  }

  & .no-tab-header {
    & .mat-mdc-tab-header {
      @apply hidden;
    }
  }

  & .mat-mdc-tab-body {
    @apply p-5
    border
    border-solid
    border-light-base-300
    dark:border-dark-base-300
    rounded-md
    rounded-tl-none
    bg-light-base-100
    dark:bg-dark-base-0
    overflow-hidden;
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    &.mat-tab-body-active {
      @apply overflow-x-hidden
      overflow-y-hidden;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    & .mat-tab-body-content {
      @apply overflow-hidden
      p-4;
    }
  }

  & .round mat-tab-body {
    @apply rounded;
  }
  & .mat-mdc-tab {
    @apply h-10
      min-h-[40px]
    min-w-0
    px-4
    border
    border-solid
    border-transparent
    border-b-0
    rounded
    rounded-bl-none
    rounded-br-none
    opacity-100;
    &.mdc-tab--active {
      @apply bg-light-base-100
      dark:bg-dark-base-0
      opacity-100
      border-light-base-300
      dark:border-dark-base-300
      overflow-visible;
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      & .mat-tab-label-content {
        @apply text-light-base-400
        dark:text-dark-base-400;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    & .mat-tab-label-content {
      @apply text-light-base-400
      dark:text-dark-base-400;
    }
    &:hover .mdc-tab__ripple::before,
    &:active .mdc-tab__ripple::before,
    & .mdc-tab__ripple::before {
      @apply opacity-0 #{!important};
    }
  }
}
