app-streams {
  .mat-mdc-tab-header {
    @apply h-13 box-border overflow-visible max-w-[calc(100%-200px)];
  }

  .add-stream-button-wrapper {
    @apply flex-1 flex items-center justify-end px-3 box-border absolute top-0 right-0 h-[52px] w-[200px] border-solid border-0 border-b border-light-base-300 dark:border-dark-base-300;
  }

  button.mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-before {
    @apply border-solid border-0 border-r border-b border-light-base-300 dark:border-dark-base-300;
    box-shadow: 3px 0 5px -3px #00000030;
    &.mat-mdc-tab-header-pagination-disabled {
      box-shadow: none;
    }
  }

  button.mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-after {
    @apply border-solid border-0 border-l border-r border-b border-light-base-300 dark:border-dark-base-300;
    box-shadow: -3px 0 5px -3px #00000030;
    &.mat-mdc-tab-header-pagination-disabled {
      box-shadow: none;
    }
  }

  .add-new-tab.mdc-tab.mat-mdc-tab {
    @apply relative justify-center w-15 min-w-[auto] pointer-events-auto z-0;
    & .mdc-tab-indicator {
      @apply hidden;
    }
    & .mdc-tab__text-label,
    .mdc-tab__content {
      @apply w-9;
    }
    &.mdc-tab-indicator--active {
      & .mdc-tab__text-label {
        @apply text-light-base-400 dark:text-dark-base-400 #{!important};
      }
    }
    &.mat-mdc-tab-disabled {
      @apply opacity-100 #{!important};
    }
  }

  .mat-mdc-tab-labels,
  .mdc-tab.mat-mdc-tab {
    @apply h-13 box-border border-solid border-0 border-b border-light-base-300 dark:border-dark-base-300;
  }

  .mdc-tab-indicator {
    @apply h-auto top-0;
  }

  .mdc-tab__content,
  .mdc-tab__text-label {
    @apply w-full;
  }

  & .mdc-tab.mat-mdc-tab {
    @apply h-13 px-3 py-0 justify-start font-normal border-solid border-0 border-r border-light-base-300 dark:border-dark-base-300 text-light-base-400 dark:text-dark-base-400;

    &:hover,
    &.mdc-tab--active,
    &.mdc-tab__ripple::before,
    &:hover .mdc-tab__ripple::before,
    & .mdc-tab__ripple::before {
      @apply bg-transparent dark:bg-transparent;
    }

    &:last-child {
      @apply border-r-0;
    }

    &.mdc-tab--active {
      @apply font-medium;

      &:after {
        @apply absolute left-0 bottom-0 w-full h-px bg-light-base-100 dark:bg-dark-base-0 content-['_'];
      }

      &:hover {
        @apply bg-light-base-100 dark:bg-dark-base-0;
      }
    }
  }
}

.tab-edit {
  @apply w-full text-sm py-1.5 px-1 select-text border-0 border-b border-dashed border-light-brand-100 dark:border-dark-brand-100 rounded-t-sm outline-0 text-light-base-600 dark:text-dark-base-600;
}
