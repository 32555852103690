.create-schedule {
  overflow: hidden;

  & .schedule {
    display: flex;
    flex-direction: row;

    & app-tags-input mat-form-field {
      width: 100%;
    }

    & .schedule-details {
      display: block;
      overflow: hidden;
      flex: 1;
    }

    & .schedule-date {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & .start-date,
      & .end-date {
        width: 206px;

        & mat-form-field {
          width: 100%;
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
          & .mat-form-field-wrapper {
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }

    .schedule-timeslots {
      display: flex;
      flex-direction: column;
      position: relative;
      margin-top: $margin-xl;
      margin-bottom: $margin-xl;
      padding: 0 $padding-xl;

      & .timeslots-vertical-line {
        position: absolute;
        width: 1px;
        top: 0;
        bottom: 0;
        left: 1px;
        font-size: 0;
        line-height: 0;
        @apply bg-light-base-200 dark:bg-dark-base-200;
      }

      &::before,
      &::after {
        position: absolute;
        content: "";
        width: 3px;
        height: 3px;
        border-radius: 50%;
        top: -2px;
        left: 0;
        z-index: 10000;
        @apply bg-light-base-100 dark:bg-dark-base-200;
      }

      &::after {
        top: unset;
        bottom: -2px;
      }

      & .time {
        &::before {
          content: "";
          position: absolute;
          left: -4px;
          height: 11px;
          width: 11px;
          border-radius: 50%;
          z-index: 999;
          @apply bg-white dark:bg-dark-base-100;
        }

        &::after {
          content: "";
          position: absolute;
          left: -2px;
          height: 7px;
          width: 7px;
          border-radius: 50%;
          z-index: 1000;
          @apply bg-light-base-200 dark:bg-dark-base-200;
        }
      }
    }

    .day-toggles {
      min-height: 44px;

      .mat-button-toggle-appearance-standard {
        @apply bg-light-base-0 dark:bg-dark-base-100;

        &.mat-button-toggle-checked {
          @apply bg-light-brand-100 dark:bg-dark-brand-100;
        }
      }

      &.day-toggles-error.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
        @apply border-light-danger-100 dark:border-dark-danger-100;
      }

      .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        line-height: 40px;
      }

      & mat-button-toggle {
        position: relative;
        width: calc(100% / 7);
        height: 40px;
        margin: 2px 1px;
        border-radius: $border-radius-xs;
        @apply border-none dark:border-none;

        &:hover {
          &::before {
            display: none;
          }

          &::after {
            display: none;
          }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        &:hover + mat-button-toggle {
          &::before {
            display: block;
          }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        &.mat-button-toggle-checked {
          &::after {
            display: none;
          }
        }

        &::after {
          position: absolute;
          display: block;
          content: "";
          width: 1px;
          height: 20px;
          top: 10px;
          right: 0;
          @apply bg-light-base-200 dark:bg-dark-base-200;
        }

        &::before {
          position: absolute;
          display: none;
          content: "";
          width: 1px;
          height: 20px;
          top: 10px;
          left: 0;
          @apply bg-light-base-200 dark:bg-dark-base-200;
        }

        &:first-child {
          margin: 2px 1px 2px 2px;

          &::before {
            display: none;
          }
        }

        &:last-child {
          margin: 2px 2px 2px 1px;

          &::after {
            display: none;
          }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        & .mat-button-toggle-ripple {
          border-radius: $border-radius-xs;
        }
      }

      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      & .mat-button-toggle-label-content {
        @apply text-light-base-400 dark:text-dark-base-400;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      & .mat-button-toggle-checked .mat-button-toggle-label-content {
        font-weight: 500;
        color: white;
      }
    }

    .day-error {
      @apply flex items-center px-4 mt-1 text-xs leading-none;
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  app-timezone-selector .mat-mdc-form-field .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}
