//
// Light Theme
//

.carousel-wrapper {
  position: relative;
  overflow: hidden;
  & button {
    opacity: 100% !important;
  }
}

.carousel-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 500;
  font-size: 14px;
  opacity: 0.65 !important;
  background-color: rgba(0, 0, 0, 75) !important;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;

  &.previous {
    left: 10px;
    & .mat-icon {
      margin-left: -1px;
    }
  }

  &.next {
    right: 10px;
    & .mat-icon {
      margin-right: -1px;
    }
  }
}

.carousel-dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  padding: 0;
  margin: 0;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 500;

  & .carousel-dot {
    display: inline-flex;
    margin-left: 4px;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.3;
    border: 1px solid rgba(0, 0, 0, 0.5);

    &.active {
      opacity: 1;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.carousel-icon {
  position: absolute;
  display: block;
  height: 24px;
  width: 24px;
  top: 10px;
  right: 10px;
  z-index: 500;

  & mat-icon {
    height: 100%;
    width: 100%;
    color: white;
  }
}

.carousel {
  display: flex;
  flex-direction: row;
  background: $LT-modal;
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  font-size: 0;
  line-height: 0;
  padding-bottom: 125%;

  & .carousel-item {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    user-select: none;
    transition: transform cubic-bezier(0.21, 0.6, 0.35, 1) 0.25s;

    & .media {
      width: 100%;
      height: calc(100% + 1px);
      object-fit: cover;
    }
  }
}
