:root {
  // light base color
  // darkens as it increases
  --light-base-0: 256 256 256; // white / cards (activity, settings)
  --light-base-50: 251 251 251; // background
  --light-base-100: 242 242 242; // none white dialogs side nav color
  --light-base-200: 230 230 230; // sidenav background on hover / icon button background on hover
  --light-base-300: 218 220 224; // border color
  --light-base-400: 127 127 127; // secondary text
  --light-base-500: 97 97 97; // icons
  --light-base-600: 61 61 61; // nav icons hover / primary text
  // dark base color
  // lightens as it increases
  --dark-base-0: 12 17 20; // background
  --dark-base-25: 25 30 36; // table headers
  --dark-base-50: 28 33 39; // cards (activity, settings)
  --dark-base-100: 32 37 44; // menu
  --dark-base-200: 36 41 48; // hover
  --dark-base-300: 37 41 44; // border color
  --dark-base-400: 163 165 167; // secondary text
  --dark-base-500: 156 157 158; // icons
  --dark-base-600: 215 215 216; // nav icons hover / primary text

  // light brand
  --light-brand-100: 23 114 232;
  --light-brand-200: 13 85 222;
  // dark brand
  --dark-brand-100: 71 142 237;
  --dark-brand-200: 117 170 241;

  // light danger
  --light-danger-100: 185 28 28;
  --light-danger-200: 153 27 27;
  // dark danger
  --dark-danger-100: 248 113 113;
  --dark-danger-200: 252 165 165;

  // light success
  --light-success-100: 4 120 87;
  // dark success
  --dark-success-100: 16 185 129;

  // light warning
  --light-warning-100: 232 155 23;
  // dark warning
  --dark-warning-100: 236 183 93;

  // angular material checkbox
  --mdc-checkbox-state-layer-size: 36px;
  --mat-paginator-container-size: 50px;
  --mdc-icon-button-icon-size: 20px;
  --mdc-linear-progress-track-height: 6px;
  --mdc-linear-progress-active-indicator-height: 6px;
}

// Common colors
$white: #ffffff;
$black: #000000;
$periwinkle: #ccccff;
$lavender: #e6e6fa;
$grape: #6f2da8;

// Brands
$facebook: #1877f2;
$twitter: #1da1f2;
$google: #db4437;
$instagram: #c13584;
$linkedin: #0073b1;

// Light theme (LT) colors
$LT-brand-primary: #1772e8;
$LT-brand-light: #b9d5f8;
$LT-brand-faint: #d4e5fa;
$LT-brand-dark: #0d55de;
$LT-background: #f2f2f2;
$LT-success-primary: #5fa25f;
$LT-success-dark: #438643;
$LT-warn-primary: #b91c1c;
$LT-warn-light: #e7b3bc;
$LT-warn-dark: #970013;
$LT-caution-primary: #e89b17;
$LT-primary-text: rgba(0, 0, 0, 0.75);
$LT-secondary-text: rgba(0, 0, 0, 0.6);
$LT-tertiary-text: rgba(0, 0, 0, 0.5);
$LT-muted-text: rgba(0, 0, 0, 0.3);
$LT-border: rgba(0, 0, 0, 0.1);
$LT-border-light: rgba(0, 0, 0, 0.05);
$LT-preview-overlay: rgba(0, 0, 0, 0.01);
$LT-hover: rgba(0, 0, 0, 0.04);
$LT-card: #ffffff;
$LT-card-sheet: #f8f8f8;
$LT-card-sheet-dark: #f2f2f2;
$LT-modal: #f9f9f9;
$LT-sidenav: #ffffff;
$LT-backdrop: rgba(0, 0, 0, 0.5);

// Dark theme (DK) colors
$DK-brand-primary: #2f80e7;
$DK-brand-light: #a2c7f6;
$DK-brand-faint: #b9d4f6;
$DK-brand-dark: #2f80e7;
$DK-background: #0d1115;
$DK-success-primary: #5decae;
$DK-success-dark: #3c9c6d;
$DK-warn-primary: #fca5a5;
$DK-warn-light: #e7b4bd;
$DK-warn-dark: #cf6679;
$DK-caution-primary: #ecb75d;
$DK-primary-text: rgba(250, 250, 250, 0.85);
$DK-secondary-text: rgba(250, 250, 250, 0.6);
$DK-tertiary-text: rgba(250, 250, 250, 0.45);
$DK-muted-text: rgba(250, 250, 250, 0.3);
$DK-border: rgba(250, 250, 250, 0.1);
$DK-border-light: rgba(250, 250, 250, 0.075);
$DK-preview-overlay: rgba(250, 250, 250, 0.02);
$DK-hover: rgba(250, 250, 250, 0.04);
$DK-card: #1c2127;
$DK-card-sheet: #161b20;
$DK-card-sheet-dark: #151a1f;
$DK-modal: #21272e;
$DK-sidenav: #161b20;
$DK-backdrop: rgba(255, 255, 255, 0.075);
// One Off
$DK-brand-primary-link: #58a6ff;

// z-indexes
$z-patterns: 100;
$z-cards: 200;
$z-icons: 300;
$z-menus: 400;
$z-content: 500;

// borders
$border-radius-xs: 4px;
$border-radius-sm: 4px;
$border-radius-md: 6px;
$border-radius-lg: 8px;
$border-radius-xl: 24px;

// shadows
$card-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
$left-scrollpane-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);

// components
$page-header-height: 52px;
$sidenav-width: 52px;
$activity-footer-height: 44px;
$activity-reactions-height: 40px;
$activity-preheader: 40px;
$in-page-nav-height: 36px;
$settings-menu-max-width: 240px;

// padding
$padding-xxs: 2px;
$padding-xs: 4px;
$padding-sm: 8px;
$padding-md: 12px;
$padding-lg: 16px;
$padding-xl: 20px;
$padding-xxl: 30px;

// margin
$margin-xxs: 2px;
$margin-xs: 4px;
$margin-sm: 8px;
$margin-md: 12px;
$margin-lg: 16px;
$margin-xl: 20px;
$margin-xxl: 30px;

:root {
  // Common colors
  --white: #{$white};
  --black: #{$black};
  --periwinkle: #{$periwinkle};
  --lavender: #{$lavender};
  --grape: #{$grape};

  // Brands
  --facebook: #{$facebook};
  --twitter: #{$twitter};
  --google: #{$google};
  --instagram: #{$instagram};
  --linkedin: #{$linkedin};

  // Light theme (LT) colors
  --LT-brand-primary: #{$LT-brand-primary};
  --LT-brand-light: #{$LT-brand-light};
  --LT-brand-faint: #{$LT-brand-faint};
  --LT-brand-dark: #{$LT-brand-dark};
  --LT-background: #{$LT-background};
  --LT-success-primary: #{$LT-success-primary};
  --LT-success-dark: #{$LT-success-dark};
  --LT-warn-primary: #{$LT-warn-primary};
  --LT-warn-light: #{$LT-warn-light};
  --LT-warn-dark: #{$LT-warn-dark};
  --LT-caution-primary: #{$LT-caution-primary};
  --LT-primary-text: #{$LT-primary-text};
  --LT-secondary-text: #{$LT-secondary-text};
  --LT-tertiary-text: #{$LT-tertiary-text};
  --LT-muted-text: #{$LT-muted-text};
  --LT-border: #{$LT-border};
  --LT-border-light: #{$LT-border-light};
  --LT-preview-overlay: #{$LT-preview-overlay};
  --LT-hover: #{$LT-hover};
  --LT-card: #{$LT-card};
  --LT-card-sheet: #{$LT-card-sheet};
  --LT-card-sheet-dark: #{$LT-card-sheet-dark};
  --LT-modal: #{$LT-modal};
  --LT-sidenav: #{$LT-sidenav};
  --LT-backdrop: #{$LT-backdrop};

  // Dark theme (DK) colors
  --DK-brand-primary: #{$DK-brand-primary};
  --DK-brand-light: #{$DK-brand-light};
  --DK-brand-faint: #{$DK-brand-faint};
  --DK-brand-dark: #{$DK-brand-dark};
  --DK-background: #{$DK-background};
  --DK-success-primary: #{$DK-success-primary};
  --DK-success-dark: #{$DK-success-dark};
  --DK-warn-primary: #{$DK-warn-primary};
  --DK-warn-light: #{$DK-warn-light};
  --DK-warn-dark: #{$DK-warn-dark};
  --DK-caution-primary: #{$DK-caution-primary};
  --DK-primary-text: #{$DK-primary-text};
  --DK-secondary-text: #{$DK-secondary-text};
  --DK-tertiary-text: #{$DK-tertiary-text};
  --DK-muted-text: #{$DK-muted-text};
  --DK-border: #{$DK-border};
  --DK-border-light: #{$DK-border-light};
  --DK-preview-overlay: #{$DK-preview-overlay};
  --DK-hover: #{$DK-hover};
  --DK-card: #{$DK-card};
  --DK-card-sheet: #{$DK-card-sheet};
  --DK-card-sheet-dark: #{$DK-card-sheet-dark};
  --DK-modal: #{$DK-modal};
  --DK-sidenav: #{$DK-sidenav};
  --DK-backdrop: #{$DK-backdrop};

  --DK-brand-primary-link: #{$DK-brand-primary-link};

  // z-indexes
  --z-patterns: #{$z-patterns};
  --z-cards: #{$z-cards};
  --z-icons: #{$z-icons};
  --z-menus: #{$z-menus};
  --z-content: #{$z-content};

  // borders
  --border-radius-xs: #{$border-radius-xs};
  --border-radius-sm: #{$border-radius-sm};
  --border-radius-md: #{$border-radius-md};
  --border-radius-lg: #{$border-radius-lg};
  --border-radius-xl: #{$border-radius-xl};

  // shadows
  --card-shadow: #{$card-shadow};
  --left-scrollpane-shadow: #{$left-scrollpane-shadow};

  // components
  --page-header-height: #{$page-header-height};
  --sidenav-width: #{$sidenav-width};
  --activity-footer-height: #{$activity-footer-height};
  --activity-reactions-height: #{$activity-reactions-height};
  --activity-preheader: #{$activity-preheader};
  --in-page-nav-height: #{$in-page-nav-height};

  // padding
  --padding-xs: #{$padding-xs};
  --padding-sm: #{$padding-sm};
  --padding-md: #{$padding-md};
  --padding-lg: #{$padding-lg};
  --padding-xl: #{$padding-xl};
  --padding-xxl: #{$padding-xxl};

  // margin
  --margin-xxs: #{$margin-xxs};
  --margin-xs: #{$margin-xs};
  --margin-sm: #{$margin-sm};
  --margin-md: #{$margin-md};
  --margin-lg: #{$margin-lg};
  --margin-xl: #{$margin-xl};
  --margin-xxl: #{$margin-xxl};
}
