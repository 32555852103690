.date-range-menu {
  max-width: unset !important;

  & .mat-mdc-menu-content {
    @apply p-0;
  }
}

.analytics-data-range-selector {
  @apply flex;

  & .data-range-input button {
    @apply border border-solid border-light-base-300 dark:border-dark-base-300 rounded-l-none border-l-0;
  }

  & .data-range-preset button {
    @apply border border-solid border-light-base-300 dark:border-dark-base-300 rounded-r-none rounded-l-none border-l-0 px-2.5;
  }

  & .analytics-channel-selector.mat-mdc-form-field {
    @apply max-w-[180px] overflow-hidden border border-solid border-light-base-300 dark:border-dark-base-300 rounded-r-none h-9 box-border rounded-l bg-light-base-0 dark:bg-dark-base-25;

    & .mat-mdc-form-field-infix {
      @apply p-0 h-[34px] min-h-[34px] #{!important};

      & mat-select {
        @apply flex items-center h-[34px] min-h-[34px];
      }
    }

    & .mat-mdc-text-field-wrapper {
      @apply px-0;
    }
  }

  & .mat-mdc-form-field-flex {
    @apply h-[34px];
  }

  & .mdc-notched-outline__leading,
  & .mdc-notched-outline__notch,
  & .mdc-notched-outline__trailing {
    @apply border-0 #{!important};
  }

  & .mat-mdc-select-arrow-wrapper {
    @apply hidden;
  }

  & .mat-mdc-select-value-text {
    @apply ml-2.5;
  }
}

.analytics-channel-selector-option {
  @apply h-12 #{!important};
  & .mat-pseudo-checkbox {
    @apply mr-3;
  }

  & .mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-full {
    @apply border h-4 w-4;
  }

  & .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
    @apply text-light-base-0 dark:text-light-base-0;
  }
}

.analytics-channel-selector-menu-wrapper {
  @apply dark:border dark:border-solid dark:border-dark-base-300/90 bg-light-base-0 dark:bg-dark-base-100 z-[100] mt-2 rounded-[4px] shadow #{!important};
}
