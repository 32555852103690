//
// Light theme
//
.pane-header,
.pane-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: $LT-sidenav;
  height: $page-header-height;
  padding: 0 $padding-sm 0 $padding-lg;
  border-bottom: 1px solid;
  border-color: $LT-border;
  white-space: nowrap;
  box-sizing: border-box;

  & .title,
  & .actions {
    color: $LT-primary-text;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
  }

  & .actions {
    display: flex;
    flex-direction: row;
  }
}

.pane-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  border-bottom: unset;
  border-top: 1px solid $LT-border;
}

.pane-body {
  display: flex;
  height: calc(100vh - #{$page-header-height});

  &.notifications-dialog {
    flex-direction: column;
    width: 520px;
  }
}

.composer-editor {
  width: 460px;

  & app-composer-actions {
    margin-top: $margin-xl;

    & .schedule-time {
      & .time,
      & app-time-picker {
        width: 100%;
      }
    }
  }

  & .app-scrollbar {
    & .ng-scroll-viewport-wrapper {
      overflow: visible !important;

      & .ng-scroll-viewport {
        contain: unset !important;
      }
    }
  }
}

.composer-preview {
  width: 460px;
}

.composer-wrapper {
  app-alert .app-alert {
    margin-bottom: 0;
  }
}

//
// Dark theme
//
.dark {
  & .pane-header {
    background-color: $DK-sidenav;
    border-bottom: 1px solid $DK-border;

    & .title {
      color: $DK-primary-text;
    }
  }

  & .pane-footer {
    border-bottom: unset;
    border-top: 1px solid $DK-border;
    background-color: $DK-sidenav;
  }

  & .composer-channels {
    & app-profile-picture {
      & .social-badge {
        border-color: $DK-background !important;
      }
    }
  }
}
