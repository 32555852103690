.invite-users-dialog {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  mat-form-field .mat-form-field-wrapper {
    @apply pb-0;
  }
  .user-invite-accordion {
    .mat-expansion-panel {
      @apply shadow-none box-border bg-white dark:bg-dark-base-50 border border-solid border-light-base-300/90 dark:border-dark-base-300/90 rounded-md;
      .mat-action-row,
      .mat-expansion-panel-body,
      .mat-expansion-panel-header {
        @apply px-5;
      }
    }
  }
}
