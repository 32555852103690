.timezone-menu {
  @apply mt-2 rounded-[4px] #{!important};
  &.mat-mdc-autocomplete-panel {
    @apply bg-light-base-0 dark:bg-dark-base-25 border border-b-0 border-solid border-transparent dark:border-dark-base-300/90;
  }
}
app-timezone-selector {
  .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
    @apply pr-1;
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    @apply self-start py-1;
  }
}
