//
// Light theme
//
.auth-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 360px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: $LT-background;
}

.auth-root {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  min-height: 100vh;
  width: 100%;
  position: relative;
  background: $LT-card;

  mat-form-field {
    width: 100%;
  }
}

.auth-view {
  transition: all ease 0.2s;
  flex-grow: 1;
  display: block;
  width: 100%;
}

.auth-card {
  position: relative;
  padding: 28px;
  z-index: $z-cards;
  background: $LT-card;
  color: $LT-secondary-text;

  & .confirm-email {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background: $LT-brand-primary;
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }
}

.auth-continue .mat-mdc-button-base.block {
  @apply h-10 min-h-[40px];
}

.auth-heading-text-wrapper {
  display: flex;
  margin: 0;
  margin-bottom: 20px;
  justify-content: center;
}

.auth-heading-subtext-wrapper {
  display: flex;
  margin-bottom: 20px;
}

.auth-heading-text {
  font-size: 16px;
  line-height: 1.4;
  color: $LT-primary-text;
}

.auth-heading-subtext {
  color: $LT-secondary-text;
}

.auth-credentials {
  display: flex;
  flex-direction: column;
  @apply text-light-base-600 dark:text-dark-base-400;
}

.auth-forgot-password {
  text-align: right;
  margin-bottom: 4px;
  font-weight: 500;
}

.auth-signup-link {
  margin-left: 4px;
}

.auth-privacy {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.auth-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 28px 0;
  font-size: 12px;
  z-index: $z-cards;

  & a {
    font-weight: 500;
    color: $LT-muted-text;

    &:hover {
      color: $LT-secondary-text;
    }
  }
}

.auth-login-with {
  display: flex;
  width: 100%;
  margin: 20px 0;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  & .line {
    @apply h-px w-full bg-light-base-300/90 dark:bg-dark-base-300/90;
  }

  & .text {
    @apply absolute p-2.5 bg-light-base-0 dark:bg-dark-base-0 text-light-base-400 dark:text-dark-base-400;
  }
}

.auth-show-pass {
  & mat-icon {
    height: 24px !important;

    & svg {
      width: 20px;
    }
  }
}

.auth-logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 28px;

  & app-logo-mark {
    margin-right: 10px;
  }
}

.auth-spacer {
  display: block;
  width: 15px;
  text-align: center;
  color: $LT-muted-text;
}

.auth-register-consent {
  font-weight: 400;
  color: $LT-secondary-text;

  & mat-checkbox {
    display: flex;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  & .mat-checkbox-layout {
    white-space: normal;
    position: relative;
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    & .mat-checkbox-inner-container {
      position: absolute;
      top: 4px;
    }

    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    & .mdc-label {
      padding-left: 24px;
    }
  }

  > div {
    margin-bottom: 4px;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  & .mat-checkbox-frame,
  & .mat-checkbox-disabled .mat-checkbox-frame {
    border-width: 1.4px;
    border-color: $LT-border;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  & .mat-checkbox-disabled .mat-checkbox-background {
    @apply bg-light-base-100 dark:bg-dark-base-0;
  }
}

.auth-register-consent .checkbox-error {
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  & .mat-checkbox-frame {
    border-width: 2px;
    border-color: $LT-warn-primary;
  }
}

.auth-first-last {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.auth-link-social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  & .auth-link-social-icon {
    padding: 20px;

    & svg {
      transform: rotate(90deg);
      color: $LT-brand-primary;
    }
  }
}

.grecaptcha-badge {
  box-shadow: $card-shadow !important;
}

.pattern {
  opacity: 0.3;
}

//
// Dark theme
//
.dark {
  .auth-root {
    background: $DK-card;
  }

  & .auth-wrapper {
    background: $DK-background;
  }

  & .auth-card {
    background: $DK-card;
    color: $DK-secondary-text;

    & .confirm-email {
      background: $DK-brand-primary;
    }
  }

  & .auth-heading-text {
    color: $DK-primary-text;
  }

  & .auth-heading-subtext {
    color: $DK-secondary-text;
  }

  .auth-register-consent {
    color: $DK-secondary-text;
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    & .mat-checkbox-frame {
      border-color: $DK-border;
    }
  }

  .auth-footer {
    & a {
      color: $DK-muted-text;

      &:hover {
        color: $DK-secondary-text;
      }
    }
  }

  .auth-register-consent .checkbox-error {
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    & .mat-checkbox-frame {
      border-color: $DK-warn-primary;
    }
  }

  .auth-spacer {
    color: $DK-muted-text;
  }

  & .auth-link-social-icon {
    & svg {
      color: $DK-brand-primary;
    }
  }

  .auth-social-login {
    & .mat-mdc-outlined-button:hover {
      /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
      & .mat-button-wrapper {
        & .mat-icon {
          filter: unset;

          &.apple {
            color: white;
          }
        }
      }
    }
  }
}

//
// Media queries
//
@media (min-width: 600px) {
  //
  // Light theme
  //
  .auth-wrapper {
    &:before {
      min-height: 30px;
    }

    &:after {
      min-height: 70px;
    }

    &:before,
    &:after {
      content: "";
      display: block;
      flex-grow: 1;
      height: 24px;
    }
  }
  .auth-root {
    min-height: auto;
    background: transparent;

    &.grande {
      max-width: 448px;
      margin: 0 auto;
    }

    &.venti {
      max-width: 480px;
      margin: 0 auto;
    }
  }
  .auth-card {
    @apply p-12 bg-light-base-0 dark:bg-dark-base-0 border border-solid border-b-2 border-light-base-300/90 dark:border-dark-base-300/90 overflow-hidden rounded-md;
  }
  .auth-footer {
    justify-content: space-between;
    align-items: center;
    padding: 20px 4px 40px 4px;

    & .auth-spacer.login {
      display: none;
    }
  }
  .auth-logo-wrapper {
    justify-content: center;
    padding: 0 0 60px 0;
  }
  .auth-first-last {
    display: flex;
    flex-direction: row;
    width: 100%;

    & .mat-mdc-form-field:first-of-type {
      margin-right: 20px;
    }
  }
  .auth-link-social {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  //
  // Dark theme
  //
  .dark {
    .auth-root {
      background: transparent;
    }
  }
}
