.base-table-widget {
  & mat-paginator {
    @apply absolute inline-flex bg-transparent dark:bg-dark-base-100 top-1.5 right-10;

    & .mat-mdc-paginator-outer-container {
      & .mat-mdc-paginator-container {
        padding: 0;
        width: unset !important;
        min-height: 24px;

        & .mat-mdc-paginator-range-label {
          display: none !important;
        }

        & .mat-mdc-paginator-range-actions {
          & .mat-mdc-icon-button {
            width: 24px !important;
            height: 24px !important;
            line-height: 24px !important;

            & .mat-mdc-paginator-icon {
              width: 24px !important;
              height: 24px !important;
            }
          }
        }
      }
    }
  }

  & app-employee-groups-filter {
    & .employee-group-filter {
      & .tag {
        font-size: 11px !important;
        padding: 1px 6px !important;
        margin: 2px !important;
        font-weight: normal !important;
      }
    }
  }

  & .btn-menu {
    & .icon-button {
      height: 20px !important;
      width: 20px !important;
      line-height: 20px !important;
      border-radius: 4px !important;
    }
  }

  & .loading-data {
    height: 18px !important;
    width: 18px !important;
  }
}
