// Colors for data series and points.
$colors: #62b3ff #8bc8d3 #bcde85 #e18731 #f5ac91 #a86cc5 #b4ace3 #df5d99 #dea1d1 #48aa9f #96d1b4 #ee7447 #f4a3a0 !default;
$colors-dark: #62b3ff #8bc8d3 #bcde85 #e18731 #f5ac91 #a86cc5 #b4ace3 #df5d99 #dea1d1 #48aa9f #96d1b4 #ee7447 #f4a3a0 !default;

// Chart background, point stroke for markers and columns etc
$background-color: #ffffff !default;
$background-color-dark: #1c2127 !default;

// Neutral colors, grayscale by default. The default colors are defined by mixing the
// background-color with neutral, with a weight corresponding to the number in the name.
$neutral-color-100: #000000 !default; // Strong text.
$neutral-color-80: #333333 !default; // Main text and some strokes.
$neutral-color-60: #666666 !default; // Axis labels, axis title, connector fallback.
$neutral-color-40: #999999 !default; // Credits text, export menu stroke.
$neutral-color-20: #cccccc !default; // Disabled texts, button strokes, crosshair etc.
$neutral-color-10: #e6e6e6 !default; // Grid lines etc.
$neutral-color-5: #f2f2f2 !default; // Minor grid lines etc.
$neutral-color-3: #f7f7f7 !default; // Tooltip backgroud, button fills, map null points.

$neutral-color-dark-100: #ffffff !default; // Strong text.
$neutral-color-dark-80: #e6e6e6 !default; // Main text and some strokes.
$neutral-color-dark-60: #cccccc !default; // Axis labels, axis title, connector fallback.
$neutral-color-dark-40: #b3b3b3 !default; // Credits text, export menu stroke.
$neutral-color-dark-20: #999999 !default; // Disabled texts, button strokes, crosshair etc.
$neutral-color-dark-10: #666666 !default; // Grid lines etc.
$neutral-color-dark-5: #333333 !default; // Minor grid lines etc.
$neutral-color-dark-3: #111111 !default; // Tooltip backgroud, button fills, map null points.

// Colored, shades of blue by default
$highlight-color-100: #003399 !default; // Drilldown clickable labels, color axis max color.
$highlight-color-80: #1772e8 !default; // Selection marker, menu hover, button hover, chart border, navigator series.
$highlight-color-60: #6685c2 !default; // Navigator mask fill.
$highlight-color-20: #ccd6eb !default; // Ticks and axis line.
$highlight-color-10: #e6ebf5 !default; // Pressed button, color axis min color.

$highlight-color-dark-100: #003399 !default; // Drilldown clickable labels, color axis max color.
$highlight-color-dark-80: #2f80e7 !default; // Selection marker, menu hover, button hover, chart border, navigator series.
$highlight-color-dark-60: #6685c2 !default; // Navigator mask fill.
$highlight-color-dark-20: #ccd6eb !default; // Ticks and axis line.
$highlight-color-dark-10: #e6ebf5 !default; // Pressed button, color axis min color.

// Fonts
$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif !default;
$title-font-size: 14px !default;
$subtitle-font-size: 1em !default;
$legend-font-size: 1em !default;
$axis-labels-font-size: 0.9em !default;

// Tooltip
$tooltip-border: 1px !default;

$tooltip-background: $neutral-color-3 !default;
$tooltip-background-dark: $neutral-color-dark-3 !default;

// Axes
$xaxis-grid-line: 0px !default;

// Range-selector
$range-selector-button-border: 0px !default;

$range-selector-input-text: $neutral-color-80 !default;
$range-selector-input-border: $neutral-color-20 !default;
$range-selector-input-text-dark: $neutral-color-dark-80 !default;
$range-selector-input-border-dark: $neutral-color-dark-20 !default;

// Data-labels
$data-label-color: $neutral-color-80 !default;
$data-label-color-dark: $neutral-color-dark-80 !default;

// Buttons
$context-button-background: $background-color !default;
$context-button-background-dark: $background-color-dark !default;

$highcharts-button-background: $neutral-color-3 !default;
$highcharts-button-border: $neutral-color-20 !default;
$highcharts-button-text: $neutral-color-80 !default;
$highcharts-button-background-dark: $neutral-color-dark-3 !default;
$highcharts-button-border-dark: $neutral-color-dark-20 !default;
$highcharts-button-text-dark: $neutral-color-dark-80 !default;

$highcharts-button-pressed-background: $highlight-color-10 !default;
$highcharts-button-pressed-border: $neutral-color-20 !default;
$highcharts-button-pressed-text: $neutral-color-80 !default;
$highcharts-button-pressed-background-dark: $highlight-color-dark-10 !default;
$highcharts-button-pressed-border-dark: $neutral-color-dark-20 !default;
$highcharts-button-pressed-text-dark: $neutral-color-dark-80 !default;

$highcharts-button-hover-background: $neutral-color-10 !default;
$highcharts-button-hover-border: $neutral-color-20 !default;
$highcharts-button-hover-text: $neutral-color-80 !default;
$highcharts-button-hover-background-dark: $neutral-color-dark-10 !default;
$highcharts-button-hover-border-dark: $neutral-color-dark-20 !default;
$highcharts-button-hover-text-dark: $neutral-color-dark-80 !default;

// Navigator
$navigator-series-fill: $highlight-color-80 !default;
$navigator-series-border: $highlight-color-80 !default;
$navigator-series-fill-dark: $highlight-color-dark-80 !default;
$navigator-series-border-dark: $highlight-color-dark-80 !default;

// Scrollbar
$scrollbar-track-background: $neutral-color-5 !default;
$scrollbar-track-border: $neutral-color-5 !default;
$scrollbar-track-background-dark: $neutral-color-dark-5 !default;
$scrollbar-track-border-dark: $neutral-color-dark-5 !default;

// Indicators
$positive-color: #06b535; // Positive indicator color
$negative-color: #f21313; // Negative indicator color
$positive-color-dark: #06b535; // Positive indicator color
$negative-color-dark: #f21313; // Negative indicator color

.highcharts-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: normal;
  z-index: 0; /* #1072 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: $font-family;
  font-size: 12px;
  user-select: none;
  touch-action: manipulation;
  outline: none;
  border-radius: 8px;
}
.highcharts-root {
  display: block;
}
.highcharts-root text {
  stroke-width: 0;
}
.highcharts-strong {
  font-weight: bold;
}
.highcharts-emphasized {
  font-style: italic;
}
.highcharts-anchor {
  cursor: pointer;
}
.highcharts-background {
  fill: $background-color;
  .dark & {
    fill: $background-color-dark;
  }
}
.highcharts-plot-border,
.highcharts-plot-background {
  fill: none;
}
.highcharts-label-box {
  fill: none;
}
.highcharts-button-box {
  fill: inherit;
}
.highcharts-tracker-line {
  stroke-linejoin: round;
  stroke: rgba(192, 192, 192, 0.0001);
  stroke-width: 22;
  fill: none;
}
.highcharts-tracker-area {
  fill: rgba(192, 192, 192, 0.0001);
  stroke-width: 0;
}

/* Titles */
.highcharts-title {
  @apply text-sm fill-light-base-600 dark:fill-dark-base-600;
}
.highcharts-subtitle {
  fill: $neutral-color-60;
  font-size: $subtitle-font-size;
}

/* Axes */
.highcharts-axis-line {
  fill: none;
  stroke: $neutral-color-20;
  .dark & {
    fill: none;
    stroke: $neutral-color-dark-20;
  }
}
.highcharts-yaxis .highcharts-axis-line {
  stroke-width: 0;
}
.highcharts-axis-title {
  fill: $neutral-color-60;
  .dark & {
    fill: $neutral-color-dark-60;
  }
}
.highcharts-axis-labels {
  fill: $neutral-color-60;
  cursor: default;
  font-size: $axis-labels-font-size;
  .dark & {
    fill: $neutral-color-dark-60;
  }
}
.highcharts-grid-line {
  fill: none;
  stroke: $neutral-color-10;
  .dark & {
    stroke: $neutral-color-dark-10;
  }
}
.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: $xaxis-grid-line;
}
.highcharts-tick {
  stroke: $highlight-color-20;
  .dark & {
    stroke: $highlight-color-dark-20;
  }
}
.highcharts-yaxis .highcharts-tick {
  stroke-width: 0;
}
.highcharts-minor-grid-line {
  stroke: $neutral-color-5;
  .dark & {
    stroke: $neutral-color-dark-5;
  }
}
.highcharts-crosshair-thin {
  stroke-width: 1px;
  stroke: $neutral-color-20;
  .dark & {
    stroke: $neutral-color-dark-20;
  }
}
.highcharts-crosshair-category {
  stroke: $highlight-color-20;
  stroke-opacity: 0.25;
  .dark & {
    stroke: $highlight-color-dark-20;
  }
}

/* Credits */
.highcharts-credits {
  cursor: pointer;
  fill: $neutral-color-40;
  font-size: 0.7em;
  transition:
    fill 250ms,
    font-size 250ms;
  .dark & {
    fill: $neutral-color-dark-40;
  }
}
.highcharts-credits:hover {
  fill: black;
  font-size: 1em;
}

/* Tooltip */
.highcharts-tooltip {
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
  transition: stroke 150ms;
}
.highcharts-tooltip text {
  fill: $neutral-color-80;
  .dark & {
    fill: $neutral-color-dark-80;
  }
}
.highcharts-tooltip .highcharts-header {
  font-size: 0.85em;
}
.highcharts-tooltip-box {
  stroke-width: $tooltip-border;
  fill: $tooltip-background;
  fill-opacity: 0.85;
  .dark & {
    fill: $tooltip-background-dark;
  }
}
.highcharts-tooltip-box .highcharts-label-box {
  fill: $tooltip-background;
  fill-opacity: 0.85;
  .dark & {
    fill: $tooltip-background-dark;
  }
}
div.highcharts-tooltip {
  filter: none;
}

.highcharts-selection-marker {
  fill: $highlight-color-80;
  fill-opacity: 0.25;
  .dark & {
    fill: $highlight-color-dark-80;
  }
}

.highcharts-graph {
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.highcharts-empty-series {
  stroke-width: 1px;
  fill: none;
  stroke: $neutral-color-20;
  .dark & {
    stroke: $neutral-color-dark-20;
  }
}

.highcharts-state-hover .highcharts-graph {
  stroke-width: 3;
}

.highcharts-point-inactive {
  opacity: 0.2;
  transition: opacity 50ms; /* quick in */
}

.highcharts-series-inactive {
  opacity: 0.2;
  transition: opacity 50ms; /* quick in */
}

.highcharts-state-hover path {
  transition: stroke-width 50ms; /* quick in */
}
.highcharts-state-normal path {
  transition: stroke-width 250ms; /* slow out */
}
/* Legend hover affects points and series */
g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
  transition: opacity 250ms;
}
.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
  opacity: 0.2;
}

/* Series options */

/* Default colors */
@for $i from 1 through length($colors) {
  $color: nth($colors, $i);
  .highcharts-color-#{$i - 1} {
    fill: $color;
    stroke: $color;
  }
}

/* Default colors */
.dark {
  @for $i from 1 through length($colors-dark) {
    $color: nth($colors-dark, $i);
    .highcharts-color-#{$i - 1} {
      fill: $color;
      stroke: $color;
    }
  }
}

.highcharts-area {
  fill-opacity: 0.75;
  stroke-width: 0;
}
.highcharts-markers {
  stroke-width: 1px;
  stroke: $background-color;
  .dark & {
    stroke: $background-color-dark;
  }
}
.highcharts-a11y-markers-hidden .highcharts-point:not(.highcharts-point-hover):not(.highcharts-a11y-marker-visible),
.highcharts-a11y-marker-hidden {
  opacity: 0;
}
.highcharts-point {
  stroke-width: 1px;
}
.highcharts-dense-data .highcharts-point {
  stroke-width: 0;
}

.highcharts-data-label {
  font-size: 11px;
  font-weight: 500;
}
.highcharts-data-label-box {
  fill: none;
  stroke-width: 0;
}
.highcharts-data-label text,
text.highcharts-data-label {
  fill: $data-label-color;
  .dark & {
    fill: $data-label-color-dark;
  }
}
.highcharts-data-label-connector {
  fill: none;
  .dark & {
    fill: none;
  }
}
.highcharts-data-label-hidden {
  pointer-events: none;
}
.highcharts-halo {
  fill-opacity: 0.25;
  stroke-width: 0;
}
.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
.highcharts-markers .highcharts-point-select {
  fill: $neutral-color-20;
  stroke: $neutral-color-100;
  .dark & {
    fill: $neutral-color-dark-20;
    stroke: $neutral-color-dark-100;
  }
}
.highcharts-column-series rect.highcharts-point {
  // rect to prevent stroke on 3D columns
  stroke: $background-color;
  .dark & {
    stroke: $background-color-dark;
  }
}
.highcharts-column-series .highcharts-point {
  transition: fill-opacity 250ms;
}
.highcharts-column-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}
.highcharts-pie-series .highcharts-point {
  stroke-linejoin: round;
  stroke: $background-color;
  .dark & {
    stroke: $background-color-dark;
  }
}
.highcharts-pie-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}
.highcharts-funnel-series .highcharts-point {
  stroke-linejoin: round;
  stroke: $background-color;
  .dark & {
    stroke: $background-color-dark;
  }
}
.highcharts-funnel-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}
.highcharts-funnel-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}
.highcharts-pyramid-series .highcharts-point {
  stroke-linejoin: round;
  stroke: $background-color;
  .dark & {
    stroke: $background-color-dark;
  }
}
.highcharts-pyramid-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}
.highcharts-pyramid-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}
.highcharts-solidgauge-series .highcharts-point {
  stroke-width: 0;
}
.highcharts-treemap-series .highcharts-point {
  stroke-width: 1px;
  stroke: $neutral-color-10;
  transition:
    stroke 250ms,
    fill 250ms,
    fill-opacity 250ms;
  .dark & {
    stroke: $neutral-color-dark-10;
  }
}
.highcharts-treemap-series .highcharts-point-hover {
  stroke: $neutral-color-40;
  transition:
    stroke 25ms,
    fill 25ms,
    fill-opacity 25ms;
  .dark & {
    stroke: $neutral-color-dark-40;
  }
}

.highcharts-treemap-series .highcharts-above-level {
  display: none;
}
.highcharts-treemap-series .highcharts-internal-node {
  fill: none;
}
.highcharts-treemap-series .highcharts-internal-node-interactive {
  fill-opacity: 0.15;
  cursor: pointer;
}
.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
  fill-opacity: 0.75;
}

.highcharts-vector-series .highcharts-point {
  fill: none;
  stroke-width: 2px;
}

.highcharts-windbarb-series .highcharts-point {
  fill: none;
  stroke-width: 2px;
}

.highcharts-lollipop-stem {
  stroke: $neutral-color-100;
  .dark & {
    stroke: $neutral-color-dark-100;
  }
}

.highcharts-focus-border {
  fill: none;
  stroke-width: 2px;
}

.highcharts-legend-item-hidden .highcharts-focus-border {
  fill: none !important;
}

/* Legend */
.highcharts-legend-box {
  fill: $background-color;
  stroke-width: 0;
  .dark & {
    fill: $background-color-dark;
  }
}
.highcharts-legend-item > text {
  fill: $neutral-color-80;
  font-weight: bold;
  font-size: $legend-font-size;
  cursor: pointer;
  stroke-width: 0;
  .dark & {
    fill: $neutral-color-dark-80;
  }
}
.highcharts-legend-item:hover text {
  fill: $neutral-color-100;
  .dark & {
    fill: $neutral-color-dark-100;
  }
}
.highcharts-legend-item-hidden * {
  fill: $neutral-color-20 !important;
  stroke: $neutral-color-20 !important;
  transition: fill 250ms;
  .dark & {
    fill: $neutral-color-dark-20 !important;
    stroke: $neutral-color-dark-20 !important;
  }
}
.highcharts-legend-nav-active {
  fill: $highlight-color-100;
  cursor: pointer;
  .dark & {
    fill: $highlight-color-dark-100;
  }
}
.highcharts-legend-nav-inactive {
  fill: $neutral-color-20;
  .dark & {
    fill: $neutral-color-dark-20;
  }
}
circle.highcharts-legend-nav-active,
circle.highcharts-legend-nav-inactive {
  /* tracker */
  fill: rgba(192, 192, 192, 0.0001);
}
.highcharts-legend-title-box {
  fill: none;
  stroke-width: 0;
}

/* Bubble legend */
.highcharts-bubble-legend-symbol {
  stroke-width: 2;
  fill-opacity: 0.5;
}
.highcharts-bubble-legend-connectors {
  stroke-width: 1;
}
.highcharts-bubble-legend-labels {
  fill: $neutral-color-80;
  .dark & {
    fill: $neutral-color-dark-80;
  }
}

/* Loading */
.highcharts-loading {
  position: absolute;
  background-color: $background-color;
  opacity: 0.5;
  text-align: center;
  z-index: 10;
  transition: opacity 250ms;
  .dark & {
    background-color: $background-color-dark;
  }
}
.highcharts-loading-hidden {
  height: 0 !important;
  opacity: 0;
  overflow: hidden;
  transition:
    opacity 250ms,
    height 250ms step-end;
}
.highcharts-loading-inner {
  font-weight: bold;
  position: relative;
  top: 45%;
}

/* Plot bands and polar pane backgrounds */
.highcharts-plot-band,
.highcharts-pane {
  fill: $neutral-color-100;
  fill-opacity: 0.05;
  .dark & {
    fill: $neutral-color-dark-100;
  }
}
.highcharts-plot-line {
  fill: none;
  stroke: $neutral-color-40;
  stroke-width: 1px;
  .dark & {
    stroke: $neutral-color-dark-40;
  }
}

/* Highcharts More and modules */
.highcharts-boxplot-box {
  fill: $background-color;
  .dark & {
    fill: $background-color-dark;
  }
}
.highcharts-boxplot-median {
  stroke-width: 2px;
}
.highcharts-bubble-series .highcharts-point {
  fill-opacity: 0.5;
}
.highcharts-errorbar-series .highcharts-point {
  stroke: $neutral-color-100;
  .dark & {
    stroke: $neutral-color-dark-100;
  }
}
.highcharts-gauge-series .highcharts-data-label-box {
  stroke: $neutral-color-20;
  stroke-width: 1px;
  .dark & {
    stroke: $neutral-color-dark-20;
  }
}
.highcharts-gauge-series .highcharts-dial {
  fill: $neutral-color-100;
  stroke-width: 0;
  .dark & {
    fill: $neutral-color-dark-100;
  }
}
.highcharts-polygon-series .highcharts-graph {
  fill: inherit;
  stroke-width: 0;
}
.highcharts-waterfall-series .highcharts-graph {
  stroke: $neutral-color-80;
  stroke-dasharray: 1, 3;
  .dark & {
    stroke: $neutral-color-dark-80;
  }
}
.highcharts-sankey-series .highcharts-point {
  stroke-width: 0;
}
.highcharts-sankey-series .highcharts-link {
  transition:
    fill 250ms,
    fill-opacity 250ms;
  fill-opacity: 0.5;
}
.highcharts-sankey-series .highcharts-point-hover.highcharts-link {
  transition:
    fill 50ms,
    fill-opacity 50ms;
  fill-opacity: 1;
}
.highcharts-venn-series .highcharts-point {
  fill-opacity: 0.75;
  stroke: $neutral-color-20;
  transition:
    stroke 250ms,
    fill-opacity 250ms;
  .dark & {
    stroke: $neutral-color-dark-20;
  }
}
.highcharts-venn-series .highcharts-point-hover {
  fill-opacity: 1;
  stroke: $neutral-color-20;
  .dark & {
    stroke: $neutral-color-dark-20;
  }
}

/* Highstock */
.highcharts-navigator-mask-outside {
  fill-opacity: 0;
}
.highcharts-navigator-mask-inside {
  fill: $highlight-color-60; /* navigator.maskFill option */
  fill-opacity: 0.25;
  cursor: ew-resize;
  .dark & {
    fill: $highlight-color-dark-60; /* navigator.maskFill option */
  }
}
.highcharts-navigator-outline {
  stroke: $neutral-color-20;
  fill: none;
  .dark & {
    stroke: $neutral-color-dark-20;
  }
}
.highcharts-navigator-handle {
  stroke: $neutral-color-20;
  fill: $neutral-color-5;
  cursor: ew-resize;
  .dark & {
    stroke: $neutral-color-dark-20;
    fill: $neutral-color-dark-5;
  }
}
.highcharts-navigator-series {
  fill: $navigator-series-fill;
  stroke: $navigator-series-border;
  .dark & {
    fill: $navigator-series-fill-dark;
    stroke: $navigator-series-border-dark;
  }
}
.highcharts-navigator-series .highcharts-graph {
  stroke-width: 1px;
}
.highcharts-navigator-series .highcharts-area {
  fill-opacity: 0.05;
}
.highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0;
}
.highcharts-navigator-xaxis .highcharts-grid-line {
  stroke-width: 1px;
  stroke: $neutral-color-10;
  .dark & {
    stroke: $neutral-color-dark-10;
  }
}
.highcharts-navigator-xaxis.highcharts-axis-labels {
  fill: $neutral-color-40;
  .dark & {
    fill: $neutral-color-dark-40;
  }
}
.highcharts-navigator-yaxis .highcharts-grid-line {
  stroke-width: 0;
}
.highcharts-scrollbar-thumb {
  fill: $neutral-color-20;
  stroke: $neutral-color-20;
  stroke-width: 1px;
  .dark & {
    fill: $neutral-color-dark-20;
    stroke: $neutral-color-dark-20;
  }
}
.highcharts-scrollbar-button {
  fill: $neutral-color-10;
  stroke: $neutral-color-20;
  stroke-width: 1px;
  .dark & {
    fill: $neutral-color-dark-10;
    stroke: $neutral-color-dark-20;
  }
}
.highcharts-scrollbar-arrow {
  fill: $neutral-color-60;
  .dark & {
    fill: $neutral-color-dark-60;
  }
}
.highcharts-scrollbar-rifles {
  stroke: $neutral-color-60;
  stroke-width: 1px;
  .dark & {
    stroke: $neutral-color-dark-60;
  }
}
.highcharts-scrollbar-track {
  fill: $scrollbar-track-background;
  stroke: $scrollbar-track-border;
  stroke-width: 1px;
  .dark & {
    fill: $scrollbar-track-background-dark;
    stroke: $scrollbar-track-border-dark;
  }
}
.highcharts-button {
  fill: $highcharts-button-background;
  stroke: $highcharts-button-border;
  cursor: pointer;
  stroke-width: 0;
  transition: fill 250ms;
  .dark & {
    fill: $highcharts-button-background-dark;
    stroke: $highcharts-button-border-dark;
  }
}
.highcharts-button text {
  fill: $highcharts-button-text;
  .dark & {
    fill: $highcharts-button-text-dark;
  }
}
.highcharts-button-hover {
  transition: fill 0ms;
  fill: $highcharts-button-hover-background;
  stroke: $highcharts-button-hover-border;
  .dark & {
    fill: $highcharts-button-hover-background-dark;
    stroke: $highcharts-button-hover-border-dark;
  }
}
.highcharts-button-hover text {
  fill: $highcharts-button-hover-text;
  .dark & {
    fill: $highcharts-button-hover-text-dark;
  }
}
.highcharts-button-pressed {
  font-weight: bold;
  fill: $highcharts-button-pressed-background;
  stroke: $highcharts-button-pressed-border;
  .dark & {
    fill: $highcharts-button-pressed-background-dark;
    stroke: $highcharts-button-pressed-border-dark;
  }
}
.highcharts-button-pressed text {
  fill: $highcharts-button-pressed-text;
  font-weight: bold;
  .dark & {
    fill: $highcharts-button-pressed-text-dark;
  }
}
.highcharts-button-disabled text {
  fill: $highcharts-button-text;
  .dark & {
    fill: $highcharts-button-text-dark;
  }
}
.highcharts-range-selector-buttons .highcharts-button {
  stroke-width: $range-selector-button-border;
}
.highcharts-range-label rect {
  fill: none;
}
.highcharts-range-label text {
  fill: $neutral-color-60;
  .dark & {
    fill: $neutral-color-dark-60;
  }
}
.highcharts-range-input rect {
  fill: none;
}
.highcharts-range-input text {
  fill: $range-selector-input-text;
  .dark & {
    fill: $range-selector-input-text-dark;
  }
}
.highcharts-range-input {
  stroke-width: 1px;
  stroke: $range-selector-input-border;
}
input.highcharts-range-selector {
  position: absolute;
  border: 0;
  width: 1px; /* Chrome needs a pixel to see it */
  height: 1px;
  padding: 0;
  text-align: center;
  left: -9em; /* #4798 */
}
.highcharts-crosshair-label text {
  fill: $background-color;
  font-size: 1.1em;
  .dark & {
    fill: $background-color-dark;
  }
}
.highcharts-crosshair-label .highcharts-label-box {
  fill: inherit;
}

.highcharts-candlestick-series .highcharts-point {
  stroke: $neutral-color-100;
  stroke-width: 1px;
  .dark & {
    stroke: $neutral-color-dark-100;
  }
}
.highcharts-candlestick-series .highcharts-point-up {
  fill: $background-color;
  .dark & {
    fill: $background-color-dark;
  }
}
.highcharts-hollowcandlestick-series .highcharts-point-down {
  fill: $negative-color;
  stroke: $negative-color;
  .dark & {
    fill: $negative-color-dark;
    stroke: $negative-color-dark;
  }
}
.highcharts-hollowcandlestick-series .highcharts-point-down-bearish-up {
  fill: $positive-color;
  stroke: $positive-color;
  .dark & {
    fill: $positive-color-dark;
    stroke: $positive-color-dark;
  }
}
.highcharts-hollowcandlestick-series .highcharts-point-up {
  fill: transparent;
  stroke: $positive-color;
  .dark & {
    stroke: $positive-color-dark;
  }
}
.highcharts-ohlc-series .highcharts-point-hover {
  stroke-width: 3px;
}
.highcharts-flags-series .highcharts-point .highcharts-label-box {
  stroke: $neutral-color-40;
  fill: $background-color;
  transition: fill 250ms;
  .dark & {
    stroke: $neutral-color-dark-40;
    fill: $background-color-dark;
  }
}
.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
  stroke: $neutral-color-100;
  fill: $highlight-color-20;
  .dark & {
    stroke: $neutral-color-dark-100;
    fill: $highlight-color-dark-20;
  }
}
.highcharts-flags-series .highcharts-point text {
  fill: $neutral-color-100;
  font-size: 11px;
  font-weight: bold;
  .dark & {
    fill: $neutral-color-dark-100;
  }
}

/* Highcharts Maps */
.highcharts-map-series .highcharts-point {
  transition:
    fill 500ms,
    fill-opacity 500ms,
    stroke-width 250ms;
  stroke: $neutral-color-20;
  stroke-width: inherit;
  .dark & {
    stroke: $neutral-color-dark-20;
  }
}
.highcharts-map-series .highcharts-point-hover {
  transition:
    fill 0ms,
    fill-opacity 0ms;
  fill-opacity: 0.5;
}
.highcharts-mapline-series .highcharts-point {
  fill: none;
}
.highcharts-heatmap-series .highcharts-point {
  stroke-width: 0;
}
.highcharts-map-navigation {
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
}
.highcharts-mapview-inset-border {
  stroke: $neutral-color-20;
  stroke-width: 1px;
  fill: none;
  .dark & {
    stroke: $neutral-color-dark-20;
  }
}
.highcharts-coloraxis {
  stroke-width: 0;
}
.highcharts-coloraxis-marker {
  fill: $neutral-color-40;
  .dark & {
    fill: $neutral-color-dark-40;
  }
}
.highcharts-null-point {
  fill: $neutral-color-3;
  .dark & {
    fill: $neutral-color-dark-3;
  }
}

/* 3d charts */
.highcharts-3d-frame {
  fill: transparent;
}

/* Exporting module */
.highcharts-contextbutton {
  fill: $context-button-background; /* needed to capture hover */
  stroke: none;
  stroke-linecap: round;
  .dark & {
    fill: $context-button-background-dark; /* needed to capture hover */
  }
}
.highcharts-contextbutton:hover {
  fill: $neutral-color-10;
  stroke: $neutral-color-10;
  .dark & {
    fill: $neutral-color-dark-10;
    stroke: $neutral-color-dark-10;
  }
}
.highcharts-button-symbol {
  stroke: $neutral-color-60;
  stroke-width: 3px;
  .dark & {
    stroke: $neutral-color-dark-60;
  }
}
.highcharts-menu {
  background: $background-color;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow:
    0 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  overflow: hidden;
  .dark & {
    border: 1px solid $neutral-color-dark-40;
    background: $background-color-dark;
    box-shadow:
      0 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%),
      0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
}
.highcharts-menu-item {
  padding: 8px 12px;
  background: none;
  color: $neutral-color-80;
  cursor: pointer;
  transition:
    background 250ms,
    color 250ms;
  .dark & {
    color: $neutral-color-dark-80;
  }
}
.highcharts-menu-item:hover {
  background: $highlight-color-80;
  color: $background-color;
  .dark & {
    background: $highlight-color-dark-80;
    color: $background-color-dark;
  }
}

/* Breadcrumbs */
.highcharts-breadcrumbs-button {
  fill: none;
  stroke-width: 0;
  cursor: pointer;
}
.highcharts-breadcrumbs-separator {
  fill: $neutral-color-60;
  .dark & {
    fill: $neutral-color-dark-60;
  }
}

/* Drilldown module */
.highcharts-drilldown-point {
  cursor: pointer;
}
.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label,
.highcharts-drilldown-axis-label {
  cursor: pointer;
  fill: $neutral-color-80;
  font-weight: bold;
  font-size: 12px;
  text-decoration: none;
  .dark & {
    fill: $neutral-color-dark-80;
    &:hover {
      fill: $neutral-color-dark-100;
    }
  }
  &:hover {
    fill: $neutral-color-100;
  }
}

.highcharts-drilldown-data-label text {
  font-size: 11px;
  font-weight: 500;
}

/* No-data module */
.highcharts-no-data text {
  @apply font-normal text-sm fill-light-base-400 dark:fill-dark-base-400;
}

/* Drag-panes module */
.highcharts-axis-resizer {
  cursor: ns-resize;
  stroke: black;
  stroke-width: 2px;
}

/* Bullet type series */
.highcharts-bullet-target {
  stroke-width: 0;
}

/* Lineargauge type series */
.highcharts-lineargauge-target {
  stroke-width: 1px;
  stroke: $neutral-color-80;
  .dark & {
    stroke: $neutral-color-dark-80;
  }
}
.highcharts-lineargauge-target-line {
  stroke-width: 1px;
  stroke: $neutral-color-80;
  .dark & {
    stroke: $neutral-color-dark-80;
  }
}

/* Annotations module */
.highcharts-annotation-label-box {
  stroke-width: 1px;
  stroke: $neutral-color-100;
  fill: $neutral-color-100;
  fill-opacity: 0.75;
  .dark & {
    stroke: $neutral-color-dark-100;
    fill: $neutral-color-dark-100;
  }
}
.highcharts-annotation-label text {
  fill: $neutral-color-10;
  .dark & {
    fill: $neutral-color-dark-10;
  }
}

/* A11y module */
.highcharts-a11y-proxy-button {
  border-width: 0;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  opacity: 0.001;
  z-index: 999;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: block;
  position: absolute;
}
.highcharts-a11y-proxy-group li {
  list-style: none;
}
.highcharts-visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(1px, 1px, 1px, 1px);
  margin-top: -3px;
  opacity: 0.01;
}
.highcharts-a11y-invisible {
  visibility: hidden;
}
.highcharts-a11y-proxy-container,
.highcharts-a11y-proxy-container-before,
.highcharts-a11y-proxy-container-after {
  position: absolute;
  white-space: nowrap;
}
g.highcharts-series,
.highcharts-markers,
.highcharts-point {
  outline: none;
}

/* Gantt */
.highcharts-treegrid-node-collapsed,
.highcharts-treegrid-node-expanded {
  cursor: pointer;
}
.highcharts-point-connecting-path {
  fill: none;
}
.highcharts-grid-axis .highcharts-tick {
  stroke-width: 1px;
}
.highcharts-grid-axis .highcharts-axis-line {
  stroke-width: 1px;
}
.highcharts-stack-labels text {
  font-weight: bold;
  fill: $neutral-color-80;
  .dark & {
    fill: $neutral-color-dark-80;
  }
}
