.composer-preview-channel-selector {
  @apply transform-none w-[418px] max-w-[418px] min-w-[418px] top-[132px] #{!important};

  mat-form-field .mat-mdc-text-field-wrapper.mdc-text-field--no-label {
    @apply px-3 bg-light-base-0 dark:bg-dark-base-100 rounded-md;
  }

  & .mat-mdc-select-panel {
    @apply max-w-[418px] min-w-[418px] p-0 box-border dark:border border-solid border-0 dark:border-dark-base-300/90 bg-light-base-0 dark:bg-dark-base-100 z-[100] rounded-[4px] #{!important};

    & .mat-mdc-option {
      @apply h-12 flex relative items-center pl-3 pr-4 py-2 box-border #{!important};

      & .mdc-list-item__primary-text {
        @apply flex items-center;
      }

      &.mat-active {
        @apply bg-light-base-200 dark:bg-dark-base-200;
      }
    }
  }
}
